import React, { useState, useRef } from "react";
import NewWarning from "../newWarning";
import CompanyDetail from "./companyDetail";

const CompanyResults = ({ data }: { data: any}) => {
  

  const windowSize = useRef([window.innerWidth, window.innerHeight]);


  return (

    <div className="Tabs">
      <div className=' ' style={{ width: '100%' }}>
       

      {/* {(data.retornoMsg.showMSG) && (
          <NewWarning
            type={data.retornoMsg.vbg_status}
            showpick={data.retornoMsg.vbg_show_pic}
            title={data.retornoMsg.vbg_title}
            text={data.retornoMsg.vbg_error_message}

          ></NewWarning>
        )} */}

      </div>
      <>
        {data && (
          <div>
            <ul className="nav">
            <li className="active" >{windowSize.current[0] > 600 ? 'Informações da Empresa' : 'Empresa'}</li>


            </ul>

            <div className="outlet">
              <CompanyDetail data={data}  />
            </div>
          </div>
        )}

      </>

    </div>
  );
};
export default CompanyResults;
