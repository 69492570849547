import axios from "axios"
import { getConfig , getHeader } from "../config/config";


export async function updateUser(data: any, token: string)
{

  try 
  {
    const response = await axios.post(
      getConfig().API_URL + `/alteraUsuario`,
      data,
      getHeader(true,`Bearer ${token}`)
    )

    return {success: true, message:response.data.usuarioAlterado, result: ''};

  } 
  catch (error: any) 
  {
    if(error)
    return {success: false, message: error.message};
  }
  
  return {success:false, message: 'Erro desconhecido. Tente novamente mais tarde.', result: ''};

}