import React, { useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "../../native-state.dev";
import ModalChangeScreen from "../ModalChangeScreen";

const MenuSearch: React.FC = () => {
  const [searchDone, setState] = useSelector((s: { searchDone: boolean }) => s.searchDone);
  const [isOpenModalRequest, setIsOpenModalRequest] = useState(false);
  const [simpleSearch, setSimpleSearch] = useState('menuSelecionado');
  const [multipleSearch, setMultipleSearch] = useState('');
  const location = useLocation();
  const [isDropOptions, setIsDropOptions] = useState(false)
    const navigate = useNavigate();

  const changeHover = () => {
    if (location.pathname === "/homeApi") {
      navigate("/search-many");
      setSimpleSearch('');
      setMultipleSearch('menuSelecionado');
    } else if (location.pathname === "/search-many") {
      navigate("/homeApi");
      setSimpleSearch('menuSelecionado');
      setMultipleSearch('');
    } else
    {
        setSimpleSearch('');
        setMultipleSearch('');
    }
  };

  const handleModalClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (searchDone) {
      event.preventDefault();
      event.stopPropagation();
      if (
        (event.currentTarget.textContent === "Pesquisa" && location.pathname === "/homeApi") ||
        (event.currentTarget.textContent === "Pesquisa Múltipla" && location.pathname === "/search-many")
      ) {
        return;
      }
      setIsOpenModalRequest(true);
      return;
    }
    changeHover();
  };

  const handleChangeScreen = () => {
    setState({ searchDone: false });
    changeHover();
    setIsOpenModalRequest(false);
  };

  const closeModal = () => setIsOpenModalRequest(false);

  const exportB = () => {
    setIsOpenModalRequest(false);
    const exportButton = document.getElementsByName("buttonExport")[0] as HTMLButtonElement | undefined;
    exportButton?.click();
  };

  return (
    <>
      <nav className="navbar navbar-expand navbar-default yamm gs1MenuNav">
        <div id="navbar-collapse-grid" className="navbar-collapse collapse" style={{ flexGrow: 0 }}>
          <ul className="navbar-nav">
            <li className={"dropdown yamm-fw menugs1Home hovernav " + simpleSearch} >
              <NavLink to="/homeApi" className="dropdown-toggle" onClick={handleModalClick}>
                Pesquisa
              </NavLink>
            </li>
            <li className={"dropdown yamm-fw menugs1Home hovernav " + multipleSearch} >
              <NavLink to="/search-many" className="dropdown-toggle" onClick={handleModalClick}>
                Pesquisa Múltipla
              </NavLink>
            </li>
          </ul>
        </div>
      </nav>

      {isOpenModalRequest && (
        <ModalChangeScreen
          isOpen={isOpenModalRequest}
          onClose={closeModal}
          onChangeScreen={handleChangeScreen}
          onExport={exportB}
        />
      )}
    </>
  );
};

export default MenuSearch;
