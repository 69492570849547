import React, { useState, useContext } from 'react';
import Modal from "react-modal";
import { IoMdClose } from 'react-icons/io';
import { getUnitName } from '../../../services/units';
import { exportFile } from "../../../services/exportFile";
import { LoginContext } from '../../../contexts/login_context';
import { StartRating } from '../../StarRating';
import Table from '../../Table';
import { showError, showWait, dismissToast, showSucess } from '../../../services/toast';

import Warning from '../../Warning';

import "./style.css"


const CompanyDetailGln = ({ data }: { data: any}) => {



  return (
    <>
      <div className='row px-3 detailBox'>

        <div className='col-12 col-md-12 mb-5'>


          <div className='row'>
            <h3 className='titleCompanyDetail'>Informação sobre a empresa que licenciou este GLN</h3>
          </div>
          <div className='row section pb-5 glnfntw'>

            <div className='col-12 col-md-12 table-responsive'>
              <table className='table'>
                <tbody>
                 
                      <tr>
                        <td className="propertyCompany borderbottom" style={{ border: 'none' }}>
                          Nome da empresa
                        </td>
                        <td className="propertyvalue borderbottom" style={{ border: 'none' }}>
                          {data.companyData.nomeEmpresa}
                        </td>
                      </tr>
                    
                  
                      <tr>
                        <td className="propertyCompany borderbottom" style={{ border: 'none' }}>
                          Endereço
                        </td>
                        {(data.companyData.endereco != "Não cadastrado") &&
                        <>
                        <td className="propertyvalueCustom borderbottom" style={{ border: 'none', lineHeight: '13px' }}>
                        {(data.companyData.endereco.streetAddress != undefined) &&
                        <>
                          <p>{data.companyData.endereco.streetAddress.value}</p>
                        </>}
                        {(data.companyData.endereco.streetAddressLine2 != undefined) &&
                        <>
                          <p>{data.companyData.endereco.streetAddressLine2.value}</p>
                        </>}
                        {(data.companyData.endereco.addressLocality !=undefined) &&
                        <>
                          <p>{data.companyData.endereco.addressLocality.value}</p>
                        </>
                        }
                        {(data.companyData.endereco.addressRegion !=undefined) &&
                        <>
                          <p>{data.companyData.endereco.addressRegion.value}</p>
                        </>
                        }
                        {(data.companyData.endereco.postalCode !=undefined) &&
                        <>
                          <p>{data.companyData.endereco.postalCode}</p>
                        </>
                        }
                        {(data.companyData.endereco.countryName !=undefined) &&
                        <>
                          <p>{data.companyData.endereco.countryName}</p>
                        </>
                        }

                        </td>
                        </>}
                        {(data.companyData.endereco == "Não cadastrado") &&
                        <>
                        <td className="propertyvalue borderbottom" style={{ border: 'none'}}>
                        Não cadastrado
                        </td>
                        </>}
                      </tr>
                    
                    
                  
                      <tr>
                        <td className="propertyCompany borderbottom" style={{ border: 'none' }}>
                          Site
                        </td>
                        <td className="propertyvalue borderbottom" style={{ border: 'none' }}>
                          {data.companyData.site}
                        </td>
                      </tr>

                      <tr>
                        <td className="propertyCompany borderbottom" style={{ border: 'none' }}>
                          Tipo de Prefixo
                        </td>
                        <td className="propertyvalue borderbottom" style={{ border: 'none' }}>
                          {data.companyData.tipoPrefixo}
                        </td>
                      </tr>

                      {/* <tr>
                        <td className="propertyCompany borderbottom" style={{ border: 'none' }}>
                          Global Location Number (GLN)
                        </td>
                        <td className="propertyvalue borderbottom" style={{ border: 'none' }}>
                          {data.companyData.globalLocationNumber}
                        </td>
                      </tr> */}

                      <tr>
                        <td className="propertyCompany borderbottom" style={{ border: 'none' }}>
                          Organização Membro GS1
                        </td>
                        <td className="propertyvalue borderbottom" style={{ border: 'none' }}>
                          {data.companyData.orgMenbroGs1}
                        </td>
                      </tr>
                      
                  
                </tbody>
              </table>
      
            </div>
          </div>
          <div className='row  mb-2 pl-3 pr-3'>
            <span className="lineInfo">
              {data.msgDate}
            </span>
          </div>

        </div>
      </div >
    </>
  );

};

export default CompanyDetailGln;
