import React, { useState, useContext } from 'react';
import Modal from "react-modal";
import { IoMdClose } from 'react-icons/io';
import { getUnitName } from '../../services/units';
import { exportFile } from "../../services/exportFile";
import { exportFileFrontEndGTIN,exportFileTest } from '../../services/exportFileFrontEndGTIN';
import { LoginContext } from '../../contexts/login_context';
import { ModalContestation } from '../ModalContestation';
import { StartRating } from '../StarRating';
import Table from '../Table';
import {showError, showWait, dismissToast, showSucess} from '../../services/toast';

import Warning from '../Warning';
import { type } from 'os';
import { exportFileFrontEndGLN, exportFileTestGln } from '../../services/exportFileFrontEndGLN';
import { useSelector } from '../../native-state.dev';
import { exportFileTestOtherKey } from '../../services/exportFileFrontEndOtherKeys';
import { exportFileTestVerifyCompany } from '../../services/exportFileFrontEndVerifyCompany';


const ExportDetail = ({dataPrepared, itemType}:{dataPrepared: any, itemType: string}) => 
{
  

  const [stateModal, setStateModal] = useState(false);
  const [extension, setExtension] = useState('');
  const [stars, setStars] = useState(0)
  const [isOpenModalContestation, setIsOpenModalContestation] = useState(false);
  const [isOpenModalFaq, setIsOpenModalFaq] = useState(false);
  const { jwt } = useContext(LoginContext);


  const downloadFile = (response:any, format:string, name:string):void =>
  {
    
    let format_type: string;
    let extension: string;

    switch (format) 
    {
      case 'xlsx':
        format_type = 'vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        extension = '.xlsx';
        break;

      case 'xls':
        format_type = 'application/vnd.ms-excel';
        extension = '.xls';
        break;
      
      case 'csv':
        format_type = 'text/csv';
        extension = '.csv';
        break;
    
      default:
        format_type = 'text/csv';
        extension = '.txt';
        break;
    }

    let file64base = `data:application/application/${format_type};base64, ${response}`;
    let a = document.createElement("a");
    a.href = file64base;
    a.download = `${name}.${extension}`;
    a.click();

  }

  const handleChange = (event:any) => 
  {
    setExtension(event.target.value)
  }


  const open = () => 
  {
    setExportButtonStatus(false);
    setStateModal(true)
    setExtension('')
  }

  const openModalFaq = () =>
  {
    setIsOpenModalFaq(true);
  }

  const closeModalFaq = () =>
  {
    setIsOpenModalFaq(false);
  }


  const close = () =>
  {
    setStateModal(false)
  }
  const [modalObje,setState] = useSelector((s: { searchDone: any; })=>s.searchDone);

  const [exportButtonStatus, setExportButtonStatus] = useState(false);
  const exportFiles = async () =>
  {
    setExportButtonStatus(true);
    
    if(extension !== '')
    {
      setState({searchDone:false});

      let toastId = showWait('Aguarde, download em andamento.');
      

      // const response: any = await exportFile(code, jwt, `${extension.toUpperCase()}`);
      // 
      // if(extension === 'xls')
      // {
      //   downloadFile(response.data.xls, 'xlsx', 'planilha');
      // }
      // else if(extension === 'txt')
      // {
      //   downloadFile(response.data.txt, 'txt', 'arquivo');
      // }
      // else
      // {
      //   downloadFile(response.data.csv, 'csv', 'arquivo');
      // }
      if(itemType=="GTIN")
        exportFileTest([exportFileFrontEndGTIN(dataPrepared)], extension);
      if(itemType=="GLN")
        exportFileTestGln([dataPrepared], extension,0);
      if(itemType=="OTHER_KEY")
        exportFileTestOtherKey([dataPrepared], extension,0);
      if(itemType=="VERIFY_COMPANY")
        exportFileTestVerifyCompany([dataPrepared], extension,0);
  
      
      setStateModal(false);
      dismissToast(toastId);
    }
    else
    {
      showError("Por favor, escolha uma extensão");
    }
    setExportButtonStatus(false);
  }


  function handleCloseModalContestation() 
  {
    setIsOpenModalContestation(false)
  }
  

  function handleOpenModalContestation() {
    setIsOpenModalContestation(true)
  }

  return (
    <>

 
    

      <div className='row printdata mt-5'>

        <button
          onClick={() => window.print()}
          style={{
            marginRight:"15px",
          }}
        >
          Imprimir
        </button>
        <button
          name='buttonExport'
          onClick={() => open()}
          style={{
            marginRight:"2rem",
          }}
        >
          Exportar
        </button>

      </div>
      

      <Modal
        isOpen={stateModal}
        onRequestClose={close}
        ariaHideApp={false}
        contentLabel="Selected Option"
        style={{
          overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex:1000,
            backgroundColor: "#262626CC",
          },
          content: {
            width: "370px",
            height: "330px",
            marginTop: "10%",
            marginBottom: 0,
            marginLeft: "auto",
            marginRight: "auto",
            zIndex:1000,
            border: "1px solid #ccc",
            background: "#fff",
            overflow: "auto",
            WebkitOverflowScrolling: "touch",
            borderRadius: "4px",
            outline: "none",
            padding: "20px",
          },
        }}
      >
          <div style={{ padding: "0.5rem" }}>
            <span
              onClick={() => close()}
              style={{ position: "absolute", right: "7px", top: "7px" }}
            >
              <IoMdClose />
            </span>
            <div
              style={{ display: "flex", flexDirection: "column", rowGap: "2rem" }}
            >
              <span
                style={{
                  color: "#002C6C",
                  fontSize: "1.375rem",
                  marginBottom: "-25px",
                }}
              >
                Exportar Arquivo
              </span>
              <span
              style={{
                color: "#002C6C",
                fontSize: "13px",
                marginBottom: "-5px",
                fontWeight: "400"
              }}>
                Escolha o formato de arquivo para exportarb
              </span>
              <span
                style={{
                  fontSize: "13px",
                  marginBottom: "-20px"
                }}
              >
              Formatos de arquivos
              </span>
              <span
                style={{
                  fontSize: "14px",
                  display: "felx",
                  flexDirection: "column"
                }}
              >
                <div>
                  <div style={{
                        display:"flex",
                        justifyContent:"left",
                        alignItems: "center",
                        marginBottom: "5px"
                      }}>
                    <input
                      type="radio"
                      value="txt"
                      name="extension"
                      checked={extension === 'txt'}
                      onChange={handleChange}
                      
                    /> &nbsp; .txt
                  </div>
                  <div style={{
                        display:"flex",
                        justifyContent:"left",
                        alignItems: "center",
                        marginBottom: "5px"
                      }}>
                    <input
                      type="radio"
                      value="xls"
                      name="extension"
                      checked={extension === 'xls'}
                      onChange={handleChange}
                    /> &nbsp; .xls
                  </div>
                  <div style={{
                        display:"flex",
                        justifyContent:"left",
                        alignItems: "center",
                        marginBottom: "5px"
                      }}>
                    <input
                      type="radio"
                      value="csv"
                      name="extension"
                      checked={extension === 'csv'}
                      onChange={handleChange}
                    /> &nbsp; .csv
                  </div>
                </div>
              </span>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  gap:10,
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >

                <button disabled={exportButtonStatus} onClick={() => exportFiles()} className="btn btn-primary btn-sm">
                    Exportar
                </button>
                <button disabled={exportButtonStatus} onClick={() => close()} className="btn btn-outline-primary btn-sm">
                  Cancelar
                </button>

              </div>
            </div>
          </div>

      </Modal> 


      

    </>
  )
};

export default ExportDetail;
