import React, { useState, useContext, useRef } from 'react';
import Modal from "react-modal";
import { IoMdClose } from 'react-icons/io';
import { getUnitName } from '../../../services/units';
import { exportFile } from "../../../services/exportFile";
import { LoginContext } from '../../../contexts/login_context';
import { ModalContestation } from '../../ModalContestation';
import { StartRating } from '../../StarRating';
import Table from '../../Table';
import { showError, showWait, dismissToast, showSucess } from '../../../services/toast';

import Warning from '../../Warning';
import LinkSetGtin from './LinkSet';
import './style.css'


const ProductDetailGtin = ({ data, code, openContestation}: { data: any, code: any, openContestation:any }) => {
  const [stateModal, setStateModal] = useState(false);
  const [extension, setExtension] = useState('');
  const [stars, setStars] = useState(0)
  const [isOpenModalFaq, setIsOpenModalFaq] = useState(false);
  const { jwt } = useContext(LoginContext);

  const image_cnp_opacity = (!data.vbg || data.vbg.productImage === '/jam.png') ? 1 : 0;
  
    function openModalFaq() {
    setIsOpenModalFaq(true);
  }

    function closeModalFaq() {

    setIsOpenModalFaq(false);
  }

  return (
    <>


      <div className='row px-3 detailBox'>

        <div className='col-12 col-md-9 mb-5'>

          {(data.vbg.hasError.error === 0 && data.messages.vbg.visible) &&

            <>
              <div className='row'>
                <h3 className='titleProductDetail'>{data.vbg.productdescription}</h3>
              </div>
              <div className='row section '>

                <div className='col-12 col-md-2'>
                  <div className='image pr-2'>
                    <img
                      width={110}
                      src={data.vbg.productImage}
                      alt={data.vbg.productdescription}
                    />
                  </div>
                </div>

                <div className='col-12 col-md-10 table-responsive glnfntw'>

                  <table className='table'>
                    <tbody>
                      <tr>
                        <td className="property borderbottom" style={{ border: 'none' }}>
                          GTIN
                        </td>
                        <td className="propertyvalue borderbottom" style={{ border: 'none' }}>
                          {data.gtin}
                        </td>
                      </tr>
                      <tr>
                        <td className="property borderbottom">
                          Descrição do Produto
                        </td>
                        <td className="propertyvalue borderbottom">


                            
                          {data.vbg.productFull.map((item: any, index: number) => (

                              (data.vbg.productFull.length === (index+1))?
                                <li key={index} className="secondaryRowsBorder lastItemRow" >
                                  {                           
                                    (item.unknown==undefined)?      
                                    "("+item.language+") "+item.value + " " 
                                    :"Não cadastrado"                                     
                                  }</li>
                                :
                                <li key={index} className="secondaryRowsBorder rowsBorder" >
                                  {                           
                                    (item.unknown==undefined)?      
                                    "("+item.language+") "+item.value  + " "
                                    :"Não cadastrado"                                     
                                  }</li>
                            ))}


                        </td>
                      </tr>
                      <tr>
                        <td className="property borderbottom" >
                          Nome da Marca
                        </td>
                        <td className="propertyValueCustom borderbottom">

                          
                             {data.vbg.brandNameFull.map((item: any, index: number) => (
                              (data.vbg.brandNameFull.length === (index+1))?
                                <li key={index} className="secondaryRowsBorder lastItemRow" >
                                {                           
                                  (item.unknown==undefined)?      
                                  "("+item.language+") "+item.value  
                                  :"Não cadastrado"                                   
                                }</li>
                                :
                                <li key={index} className="secondaryRowsBorder rowsBorder" >
                                  {                           
                                    (item.unknown==undefined)?      
                                    "("+item.language+") "+item.value  + " "+index
                                    :"Não cadastrado"                                     
                                  }</li>
                            ))}

                        </td>
                      </tr>
                      
                      <tr>
                        <td className="property borderbottom">
                          Imagem do Produto (URL)
                        </td>
                        <td className="propertyvalue borderbottom">
                          

                        {data.vbg.productImageFull.map((item: any, index: number) => (
                          (data.vbg.productImageFull.length === (index+1))?
                              <li key={index} className="secondaryRowsBorder lastItemRow" >
                                {    
                                    (item.value !== '/jam.png' && item.value!= undefined)
                                      ? <a href={item.value} target='_blank' rel='noreferrer' title='Clique para ver'>
                                        {"("+item.language+") "}
                                        {`${item.value.substring(0, 44)}...`}

                                      </a>
                                      : 'Não cadastrado'
                                  

                                }</li>
                              :
                              <li key={index} className="secondaryRowsBorder rowsBorder" >
                                {    
                                    (item.value !== '/jam.png' && item.value!= undefined)
                                      ? <a href={item.value} target='_blank' rel='noreferrer' title='Clique para ver'>
                                        {"("+item.language+") "}
                                        {`${item.value.substring(0, 44)}...`}

                                      </a>
                                      : 'Não cadastrado'
                                  

                                }</li>
                            ))}

                          
                        </td>
                      </tr>
                      <tr>
                        <td className="property borderbottom">
                          Classificação Global de Produtos (GPC)
                        </td>
                        <td className="propertyvalue borderbottom">
                          {data.vbg.gpccategorycode}
                        </td>
                      </tr>
                      <tr>
                        <td className="property borderbottom">
                          Conteúdo Líquido e Unidade de Medida
                        </td>
                        <td className="propertyvalue borderbottom">
                          {data.vbg.netContent.value} {(data.vbg.netContent.unitCode)}
                        </td>
                      </tr>
                      {(data.vbg.countryFull.length>0)&&
                      <>
                      <tr>
                        <td className="property borderbottom">
                          País de venda
                        </td>
                        <td className="propertyvalue borderbottom">
                        {data.vbg.countryFull.map((item: any, index: number) => (
                                                        
                                  "("+item.sigla+") "+item.name+", "                                  
                                
                            ))}
                          
                        </td>
                      </tr>
                      </>
                      }
                    </tbody>
                  </table>
                {(data.vbg.licenseMessage !='') &&

                  <>
                    <div className='row pt-3 mb-2 pr-3'>
                      <span className="lineInfo">
                        {data.vbg.licenseMessage}
                      </span>
                    </div>
                  </>
                }
                {/* {(data.vbg.gtinRecordStatus !='') &&
                <>
                  <div className='row pl-3 pr-3'>
                    <span className="lineInfo">
                      {data.vbg.gtinRecordStatus}
                    </span>
                  </div>
                  </>
                } */}
                </div>

              </div>

            </>
          }

          {(data.cnp.hasError.error !== 2029200299 && data.messages.cnp.visible &&

            ((data.messages != null && data.messages.cnp != null && data.messages.cnp.error == false)
              || (data.messages != null && data.messages.gepir != null && data.messages.gepir.error == false) ||
              (data.messages != null && data.messages.vbg != null && data.messages.vbg.message != "O número do código de barras (GTIN) não foi encontrado."))


          ) && (

              <div className='row section mb-5 mt-3'>


                {(data.cnp.hasError.error === 1) && (

                  <>
                    <div className='col-12 col-lg-2'></div>
                    <div className='col-12 col-lg-10 pr-0'>
                      {(data.messages.cnp.title) && (
                        <>
                        <Warning
                        type={data.vbg.isNational?'ERROR':'INFO'}
                        showpick={data.cnp.hasError.showpic}
                        title={data.messages.cnp.title} text={''}
                        ></Warning>
                        </>
                      ) }
                      
                    </div>
                    <div className='col-12 col-lg-2'></div>
                    <div className='row col-12 col-md-10 lineInfo  glnfntw'>
                      Fonte Cadastro Nacional de Produtos
                    </div>

                  </>

                )}


                {(data.cnp.hasError.error === 0) && (
                  <>

                    <div className='col-12 col-md-2'>
                      <div className='image'>
                        <img
                          width={140}
                          height={140}
                          style={{ opacity: image_cnp_opacity }}
                          src={data.cnp.productImage}
                          alt={data.cnp.productDescription}
                        />
                      </div>
                    </div>

                    <div className='col-12 col-md-10 table-responsive glnfntw'>

                      <table className='table'>

                        <tbody>

                          {(!data.vbg || data.vbg.brandName === 'Não cadastrado' || data.vbg.brandName === 'Desconhecido') &&
                            <>
                              {(data.validations.tipoGtin !== 'gtin-14') &&
                                <tr>
                                  <td className="property borderbottom">
                                    GTIN
                                  </td>
                                  <td className="propertyvalue borderbottom">
                                    {data.cnp.gtin}
                                  </td>
                                </tr>
                              }
                              {(data.validations.tipoGtin === 'gtin-14') &&
                                <tr>
                                  <td className="property borderbottom">
                                    GTIN 14
                                  </td>
                                  <td className="propertyvalue borderbottom">
                                    {data.cnp.gtin}
                                  </td>
                                </tr>
                              }
                              {(data.validations.tipoGtin === 'gtin-14') &&
                                <tr>
                                  <td className="property borderbottom">
                                    GTIN Origem
                                  </td>
                                  <td className="propertyvalue borderbottom">
                                    {data.cnp.gtinOrigin}
                                  </td>
                                </tr>
                              }
                              {(data.validations.tipoGtin === 'gtin-14') &&
                                <tr>
                                  <td className="property borderbottom">
                                    Quantidade de GTIN Origem
                                  </td>
                                  <td className="propertyvalue borderbottom">
                                    {data.cnp.quantityGtinOrigin}
                                  </td>
                                </tr>
                              }
                              <tr>
                              <td className="property borderbottom">
                                Descrição do Produto
                              </td>
                              <td className="propertyvalue borderbottom">
                                
                                
                                {data.cnp.productFull.map((item: any, index: number) => (
                                (data.cnp.productFull.length === (index+1))?
                                  <li key={index} className="secondaryRowsBorder lastItemRow" >
                                  {                           
                                    (item.unknown==undefined)?      
                                    "("+item.languageCode+") "+item.tradeItemDescription  
                                    :"Não cadastrado"                                   
                                  }</li>
                                  :
                                  <li key={index} className="secondaryRowsBorder rowsBorder" >
                                    {                           
                                      (item.unknown==undefined)?      
                                      "("+item.languageCode+") "+item.tradeItemDescription  
                                      :"Não cadastrado"                                     
                                    }</li>
                                ))}    
                                  
                                 

                              </td>
                            </tr>
                              <tr>
                                <td className="property borderbottom">
                                  Nome da Marca
                                </td>
                                <td className="propertyvalue borderbottom">
                              {data.cnp.brandNameFull.map((item: any, index: number) => (
                              (data.cnp.brandNameFull.length === (index+1))?
                                <li key={index} className="secondaryRowsBorder lastItemRow" >
                                {                           
                                  (item.unknown==undefined)?      
                                  "("+item.languageCode+") "+item.brandName  
                                  :"Não cadastrado"                                   
                                }</li>
                                :
                                <li key={index} className="secondaryRowsBorder rowsBorder" >
                                  {                           
                                    (item.unknown==undefined)?      
                                    "("+item.languageCode+") "+item.brandName  
                                    :"Não cadastrado"                                     
                                  }</li>
                              ))}         



                                </td>
                              </tr>
                            </>
                          }
                          

                

                          {(!data.vbg || data.vbg.productImage === '/jam.png')&& 

                            <tr>
                              <td className="property borderbottom">
                                Imagem do Produto (URL)
                              </td>
                              <td className="propertyvalue borderbottom">
                              
                              {data.cnp.productImageFull.map((item: any, index: number) => (
                                (data.vbg.productImageFull.length === (index+1))?
                                  <li key={index} className="secondaryRowsBorder lastItemRow" >
                                    {                           
                                      
                                        (item.unknown==undefined && item.uniformResourceIdentifier !== '/jam.png')
                                          ? <a href={item.uniformResourceIdentifier} target='_blank' rel='noreferrer' title='Clique para ver'>
                                            {"("+item.languageCode+") "}
                                            {`${item.uniformResourceIdentifier.substring(0, 44)}...`}
                                            </a>
                                          : 'Não cadastrado'
                                        

                                    }</li>
                                  :
                                  <li key={index} className="secondaryRowsBorder rowsBorder" >
                                    {                           
                                      
                                        (item.unknown==undefined && item.uniformResourceIdentifier !== '/jam.png')
                                          ? <a href={item.uniformResourceIdentifier} target='_blank' rel='noreferrer' title='Clique para ver'>
                                            {"("+item.languageCode+") "}
                                            {`${item.uniformResourceIdentifier.substring(0, 44)}...`}
                                            </a>
                                          : 'Não cadastrado'
                                        

                                    }</li>
                                
                            ))}
                             

                              </td>                             
                            </tr>


                          }
                          

                          {(!data.vbg || data.vbg.gpccategorycode === 'Não cadastrado' || data.vbg.gpccategorycode === 'Desconhecido') &&

                            <tr>
                              <td className="property borderbottom">
                                Classificação Global de Produtos (GPC)
                              </td>
                              <td className="propertyvalue borderbottom">
                                {data.cnp.classification}
                              </td>
                            </tr>

                          }
                          {(!data.vbg || (data.vbg.netContent.unitCode === '' && (data.vbg.netContent.value === 'Não cadastrado' || data.vbg.netContent.value === 'Desconhecido') )) &&
                            <tr>
                              <td className="property borderbottom">
                                Conteúdo Líquido e Unidade de Medida
                              </td>
                              <td className="propertyvalue borderbottom">
                                {data.cnp.netContent.value} {(data.cnp.netContent.measurementUnitCode)}
                              </td>
                            </tr>

                          }
                          <tr>
                            <td className="property borderbottom">
                              Peso Bruto e Unidade de Medida
                            </td>
                            <td className="propertyvalue borderbottom">
                              {data.cnp.grossWeight.value} {(data.cnp.grossWeight.measurementUnitCode)}
                            </td>
                          </tr>
                          <tr>
                            <td className="property borderbottom">
                              NCM
                            </td>
                            <td className="propertyvalue borderbottom">
                              {data.cnp.ncm}
                            </td>
                          </tr>
                          <tr>
                            <td className="property borderbottom">
                              CEST
                            </td>
                            <td className="propertyvalue borderbottom">
                              {data.cnp.cest}
                            </td>
                          </tr>
                          <tr>
                            <td className="property borderbottom">
                              Sincronizado CCG
                            </td>
                            <td className="propertyvalue borderbottom">
                              {data.cnp.syncInformationCCG}
                            </td>
                          </tr>
                        </tbody>
                      </table>

                      <div className='row pt-3 mb-2 pl-3 lineInfo pl-4'>
                        Fonte Cadastro Nacional de Produtos
                      </div>

                    </div>

                  </>

                )}

              </div>

            )}

          {(data.messages.gepir.visible &&
            ((data.messages != null && data.messages.cnp != null && data.messages.cnp.error == false)
              || (data.messages != null && data.messages.gepir != null && data.messages.gepir.error == false) ||
              (data.messages != null && data.messages.vbg != null && data.messages.vbg.message != "O número do código de barras (GTIN) não foi encontrado."))


          ) &&

            <div className='row glnfntw'>

              <div className='col-12 col-md-2'></div>

              <div className='col-12 col-md-10 pb-1'>


                <div className='row pb-1 pb-2' style={{ borderBottom: 'solid 1px #c4d7ed' }}>

                  <div className='col-12 col-md-5 pl-0'>
                    <span className='property'>Status</span>
                  </div>
                  
                  <div className='col-12 col-md-7 pl-0'>
                    {data.gepir.text == "Válido" ? (
                      <span className="propertyvalue valid">{data.gepir.text}</span>
                    ) : (
                      <span className="propertyvalue invalid">{data.gepir.text}</span>
                    )}
                  </div>

                </div>
                <div className='row infogepirerror'>
                        Por que o status retorna "{data.gepir.text}" <a href="javascript:;" style={{ fontSize: '12px' }} onClick={() => openModalFaq()}> Clique aqui</a> e entenda.
                      </div>       

                <div className='row'>


                  <div className='pt-2'>

                    {/* {data.gepir.error === 1 && (

                      <div className='row infogepirerror'>
                        Por que o status retorna "{data.gepir.text}" <a href="javascript:;" style={{ fontSize: '12px' }} onClick={() => openModalFaq()}> Clique aqui</a> e entenda.
                      </div>

                    )} */}

                    {/* <div className='row property pt-4 pl-0'>
                      Fonte GEPIR
                    </div> */}
                  
                  </div>

                </div>
                {data.vbg.linkset!=undefined && (
                      <LinkSetGtin data={data.vbg.linkset} />
                    )}
              </div>

            </div>

          }


        </div>


        {(code.length === 1 && code[0].indexOf("02") !== 0 && code[0].indexOf("20") !== 0 &&
          ((data.messages != null && data.messages.cnp != null && data.messages.cnp.error == false)
            || (data.messages != null && data.messages.gepir != null && data.messages.gepir.error == false) ||
            (data.messages != null && data.messages.vbg != null && data.messages.vbg.message != "O número do código de barras (GTIN) não foi encontrado."))

        ) &&

          <div className='col-12 col-md-3'>

            <div className='row pl-3 pb-3'>
              <div className='col-12 px-0'>
                <h6 className='pb-2' style={{ marginTop: 72 }}>Avalie os Dados do Produto</h6>
              </div>

              <div className='col-12 px-0'>
                <StartRating toAssess={setStars} star={stars} gtin={data.gtin} />
              </div>

            </div>

            <div className='row pl-3'>

              <p className='gs1-little'>Encontrou algum dado incorreto? Conte para gente.</p>
              <button
                className='btn btn-outline-primary'
                onClick={openContestation}
              >

                Enviar feedback

              </button>
              
              
            </div>

          </div>
        }

      </div>
      <Modal
        isOpen={isOpenModalFaq}
        onRequestClose={closeModalFaq}
        ariaHideApp={false}
        contentLabel="Selected Option"
        className="modal-content"
        overlayClassName="modal-overlay"
      >
        <div style={{ padding: "0.5rem" }}>
          <span
            onClick={() => closeModalFaq()}
            style={{ position: "absolute", right: "7px", top: "7px" }}
          >
            <IoMdClose />
          </span>
          <div
            style={{ display: "flex", flexDirection: "column", rowGap: "2rem" }}
          >
            <span
              style={{
                color: "#002C6C",
                fontSize: "1.3rem",
                marginBottom: "-15px",
              }}
            >
              Como identificar que o código de barras (GTIN) é válido ou inválido?
            </span>

              <div style={{
                color: "#002C6C",
                fontSize: "1.3rem",
                marginBottom: "-15px",
              }}>
                <div className=' section '>
                  <div className='custom-flex-layout col-12 col-md-12'>
                    <div className='custom-border custom-col-6 col-md-6 table-responsive custom-border2' style={{textAlign:"center"}}>
                      <span className="propertyvalue valid">Válido</span>
                        <div className='custom-margin-valid'>
                          O Código é válido quando seu status for apresentado como <b>Ativo</b> ou <b>Descontinuado</b>.
                        </div>
                    </div>
                    <div className='custom-col-6 col-md-6 table-responsive ' style={{textAlign:"center"}}>
                      <span className="propertyvalue invalid">Inválido</span>
                        <div style={{textAlign:"left", paddingTop:"18px"}}>
                          O Código é inválido quando seu status for apresentado como <b>Inativo</b>.
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            {/* <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
              className='pt-3'
            >

              <Table />

            </div> */}
          </div>
        </div>

      </Modal>
    </>
  );
};

export default ProductDetailGtin;
