import React, { useState } from "react";
import { Accordion } from "../../components/Accordion/";
import AccordionFaq from "../../components/Accordion/accordionFaq";
import  Table  from '../../components/Table/index'
import { AiOutlineCheck } from 'react-icons/ai'
import { AiOutlineClose } from 'react-icons/ai'
import { useSelector } from "../../native-state.dev";

export const data = [
  {
    'Verified by GS1': <AiOutlineCheck />,
    'Cadastro Nacional de Produtos': <AiOutlineClose />,
    GEPIR: <AiOutlineCheck />,
    Descrição: 'O código é valido. Para compartilhamento de atributos como por exemplo, NCM, CEST, Peso bruto é preciso que o dono da marca entre em contato com a GS1 Brasil'
  },
  {
    'Verified by GS1': <AiOutlineCheck />,
    'Cadastro Nacional de Produtos': <AiOutlineClose />,
    GEPIR: <AiOutlineClose />,
    Descrição: 'Nesse momento o status do código é inválido. A empresa dona da marca do produto precisa entrar em contato com a GS1 Brasil.'

  },
  {
    'Verified by GS1': <AiOutlineClose />,
    'Cadastro Nacional de Produtos': <AiOutlineCheck />,
    GEPIR: <AiOutlineCheck />,
    Descrição: 'O código é valido, porém, a empresa não permitiu o compartilhamento dos dados do produto em uma base global'

  },
  {
    'Verified by GS1': <AiOutlineClose />,
    'Cadastro Nacional de Produtos': <AiOutlineClose />,
    GEPIR: <AiOutlineCheck />,
    Descrição: 'O código é válido, porém a empresa não permite o compartilhamento dos dados do produto'

  },
]



const Faq = () => {

  let gs1_brasil_url: string = "<a href='mailto:atendimento@gs1br.org' target='_blank'>GS1 Brasil</a>";
  const [modalObje,setState] = useSelector((s: { searchDone: any; })=>s.searchDone);  
  

  setState({searchDone:false});  

  const questions = [
    {
      q: "O que é o Verified by GS1? ",
      a: "Verified by GS1 é uma solução que permite às empresas verificarem a identidade de produtos através de informações confiáveis sobre o produto (GTIN - Código de barras). Os donos de marca compartilham os dados de seus produtos com a base de dados da GS1. Os varejistas e marketplaces podem validar a identidade desses produtos acessando o Verified by GS1.  ",
    },
    {
      q: "Qual o propósito do Verified by GS1?  ",
      a: "O Verified by GS1 existe para garantir que todos os produtos comercializados possam ter seus dados consultados globalmente, através do número do código de barras (GTIN). A plataforma tem o propósito de facilitar o compartilhamento de informações de produtos e fornecer dados com maior qualidade e integridade. ",
    },
    {
      q: "Por que o Verified by GS1 foi criado?  ",
      a: "<p>A rápida expansão do comércio eletrônico e os processos inconsistentes de troca de dados, resultaram em um número crescente de produtos que não são identificados com precisão e em um excedente de informações de produtos de baixa qualidade. </p><p>O Verified by GS1 foi criado para que os proprietários de marcas tenham uma plataforma para compartilhar globalmente os dados de seus produtos e para os parceiros comerciais validarem estes dados em seus sistemas. </p>",
    },
    {
      q: "Quem pode acessar o Verified by GS1? ",
      a: "O Verified by GS1 pode ser utilizado apenas por empresas associadas da GS1 Brasil, sendo eles varejistas, mercados, proprietários de marcas ou qualquer organização envolvida na compra ou venda de produtos, que precise consultar um código de barras (GTIN) para verificar a identidade de um produto ou dados do dono da marca.  ",
    },
    {
      q: "Qual a diferença entre o Verified by GS1, o Cadastro Nacional de Produtos e o GEPIR? ",
      a: "<p>O Verified by GS1 é uma solução global que permite aos associados da GS1 Brasil verificar 7 atributos de produtos nacionais e internacionais.</p><p style={{marginTop: '2rem'}}>O Cadastro Nacional de Produtos é uma ferramenta para cadastro e gerenciamento de produtos, onde os donos de marcas associados à GS1 Brasil, cadastram informações de atributos de seus produtos e geram seus códigos de barras (GTIN). A base de dados do Cadastro Nacional de Produtos inclui outros atributos que não constam no Verified by GS1, complementando a identidade do produto. <a href='https://gs1br.org/hub-de-dados/bancos-de-dados-de-produtos/cadastro-nacional-de-produtos' style='display: inline' target='_blank'>Saiba mais</a> sobre o Cadastro Nacional de Produtos e seus benefícios.</p><p style={{marginTop: '2rem'}}>O GEPIR é uma ferramenta que fornece informações básicas de mais de um milhão de empresas nacionais e internacionais. Ele ajuda a garantir a procedência e confiabilidade dos produtos, ao indicar o status atual do número de código de barras (GTIN) pesquisado. </>",
    },
    {
      q: "Quais são os dados disponibilizados do Verified by GS1 ao consultar um número de código de barras (GTIN)? ",
      a: "<p>O Verified by GS1 compartilha 7 atributos básicos que identificam um produto, que são: </p> <ul> <li>Código GTIN;</li> <li>Nome da Marca; </li><li>Descrição do Produto;</li><li>URL da Imagem do Produto;</li><li>Classificação Global de Produtos (GPC);</li><li>Conteúdo Líquido e Unidade de Medida;</li><li>País de Venda.</li> </ul>",
    },
    {
      q: "Quais são os dados disponibilizados do Cadastro Nacional de Produtos ao consultar um número de código de barras (GTIN)? ",
      a: "<p>O site apresentará dados obrigatórios que foram cadastrados pelo dono da marca: </p> <ul><li>Marca adicional; (Se houver);</li> <li>Descrição do Produto Adicional; (Se houver); </li> <li>URL adicional da Imagem do Produto; (Se houver); </li> <li>Peso Bruto e Unidade de Medida; </li> <li>NCM</li> <li>CEST</li> <li>Status de sincronização com o CCG. </li></ul>",
    },
    {
      q: "Quais são os dados disponibilizados do GEPIR ao consultar um número de código de barras (GTIN)? ",
      a: "O Gepir não apresenta dados de produtos, diferente do Verified by GS1 e Cadastro Nacional de Produtos, o GEPIR traz apenas uma informação, indicando se o GTIN é válido ou inválido. ",
    },
    {
      q: "O Verified by GS1 faz a validação de códigos de barras GTIN-14? ",
      a: "<p>Atualmente a solução Verified by GS1 não faz a consulta de códigos de barras GTIN-14. Porém, a plataforma consegue buscar informações de produtos no Cadastro Nacional de Produtos. A partir de dados compartilhados pelo dono da marca na plataforma Cadastro Nacional de Produtos, você visualizará os seguintes atributos: </p><ul><li>GTIN origem; </li> <li>Quantidade GTIN origem;  </li> <li>Marca; </li> <li>Descrição do Produto e Idioma da Descrição do Produto;  </li> <li>URL da Imagem do Produto;  </li><li>Classificação Global do Produto (GPC);  </li><li>Conteúdo Líquido e Unidade de Medida;  </li><li>Peso Bruto e Unidade de Medida;  </li><li>NCM </li><li>CEST;</li><li>Status de sincronização com o CCG.</li></ul>",
    },
    {
      q: "O produto tem atributos que não foram cadastrados pelo dono da marca ou contém informações divergentes com o produto físico. O que devo fazer? ",
      a: "<p>Ao consultar um código de barras (GTIN) você tem a possibilidade de contestar os dados cadastrados pelo dono da marca. </p> <p>Basta clicar em “Contestar dados” e preencher o formulário informando qual o atributo será contestado, qual o problema relacionado ao atributo e quem você é (Varejo, Marketplace, e-commerce ...). </p><p>Após preencher o formulário, clique em enviar, para que possamos coletar as informações. </p><p>Esse processo ajuda os donos de marcas identificarem a qualidade das informações disponíveis para o mercado e ajustar se necessário. </p>",
    },
    {
      q: "Como faço para ter a identidade dos meus produtos disponível no Verified by GS1? ",
      a: "<p>Para disponibilizar as informações dos seus produtos no Verified by GS1 os donos de marca precisam ser associados da GS1 Brasil.</p><p>Se você ainda não é um associado, por favor, inscreva-se aqui: <a href='https://gs1br.org/codigos-e-padroes/o-que-voce-precisa/solicite-seu-codigo-de-barras' style='display: inline' target='_blank'>quero me associar!</a></p><p>Se você já é um associado, cadastre seus produtos com todos os dados completos no Cadastro Nacional de Produtos e certifique-se de que não há pendências no seu cadastro. <a href='https://cnp.gs1br.org/' style='display: inline' target='_blank'> Saiba mais </a> sobre o Cadastro Nacional de Produtos e seus benefícios. </p>",
    },
    {
      q: "Sou um varejista, como faço para consultar os GTINs dos produtos no Verified by GS1?",
      a: "<p>Para se cadastrar em nossa plataforma, Verified by GS1 Brasil, sua empresa precisa ser uma associada da GS1 Brasil.</p><p>Se você ainda não é um associado, por favor, inscreva-se aqui: <a href='https://gs1br.org/codigos-e-padroes/o-que-voce-precisa/solicite-seu-codigo-de-barras' style='display: inline' target='blank'>quero me associar!</a></p><p>Se você já é um associado, cadastre-se para consultar um número limitado de código de barras (GTINs) por dia.</p><p>Para consultar um único código de barras (GTIN), basta acessar a seção &ldquo;Pesquisa&ldquo; e digitar o número de código de barras no campo de pesquisa. </p><p>Para consultar mais de um código de barras (GTIN) simultaneamente, basta acessar a seção &ldquo;Pesquisa múltipla&ldquo;, digitar os números dos códigos de barras no box de pesquisa ou clicar no botão &ldquo;selecione um arquivo&ldquo; para carregar um arquivo em txt, csv ou xlsx com os números dos códigos de barras que serão consultados. </p>",
    },
    {
      q: "Não encontrei os dados de um produto, o que devo fazer?",
      a: "Incentive seus fornecedores a entrarem em contato com a GS1 Brasil e compartilharem os dados de seus produtos no Cadastro Nacional de Produtos, para garantir a integridade das informações.",
    },
    {
      q: "Como identificar que o código de barras (GTIN) é válido ou inválido?",
      a: <Table />,
      t: true
    },
    {
      q: "Como funciona a cota de pesquisas? ",
      a: "<p>Ao cadastrar um login você recebe uma cota de pesquisas diárias que está vinculada a sua empresa, ou seja, uma quantidade limite de pesquisas de códigos de barras (GTINs) que podem ser realizadas dentro de 24 horas.</p><p>Cada pesquisa de um código de barras (GTIN) corresponde ao consumo de uma cota, seja ela feita de forma unitária (pesquisa simples) ou de forma simultânea através da &ldquo;Pesquisa múltipla&ldquo;. </p><p>As cotas não são acumulativas. Então, mesmo que você não consuma todas as suas pesquisas disponíveis do dia, no dia seguinte suas cotas serão restauradas para a mesma quantidade limite padrão. </p>",
    },
    {
      q: "Tenho mais de um usuário cadastrado na mesma empresa, como é calculado o consumo de cotas, neste caso? ",
      a: "<p>Cada empresa possui uma quantidade limite de consultas, que será liberada e consumida por todos os usuários cadastrados igualmente. </p><p>Ressaltamos a informação de que o consumo de cota está ligado a empresa e não a cada usuário individualmente. Ou seja, os usuários poderão consumir a quantidade de consultas disponíveis por empresa. </p>",
    },
    {
      q: "Terei decréscimo na minha cota de pesquisas diárias, caso eu pesquise o mesmo número de código de barras (GTIN) várias vezes? ",
      a: "Sim! Sua cota diária será descontada a cada nova pesquisa, mesmo que ela seja repetida ou retorne um erro como resultado.  ",
    },
    {
      q: "Quanto custa o acesso ao Verified by GS1?",
      a: "<p>O acesso à plataforma é um benefício para associados. Empresas não associadas não possuem acesso à plataforma.</p><p>Se você é um varejista, marketplace, e-commerce ou qualquer mercado interessado na consulta de códigos de barras (GTINs), você precisa se associar à GS1 Brasil. Sendo um associado, você poderá se cadastrar no site e consultar dados de produtos. </p>",
    },
    {
      q: "Quais os benefícios de utilizar o Verified by GS1? ",
      a: "<p>O Verified by GS1 garante que o código de barras (GTIN) consultado é válido e não está duplicado no sistema e informa se ele está licenciado para o dono da marca.</p><p>Benefícios para os donos de marca: </p><ul><li>Processo de compartilhamento de dados simplificado e rápida entrada no mercado; </li><li>Um local centralizado para varejistas e mercados verificarem a presença de atributos para listagens de produtos;</li><li>Maior integridade da marca com o aumento da visibilidade e qualidade dos dados.</li></ul><p>Benefícios para os varejistas: </p><ul><li>Tempo e custos reduzidos para coletar e verificar dados; </li><li>Redução dos riscos de uso indevido de código de barras (GTIN); </li><li>Listagem mais rápida e processos de negócios otimizados. </li></ul><p>Benefícios para o consumidor: </p><ul><li>Transparência dos dados; </li><li>Informações de produto mais confiáveis;</li><li>Mais satisfação e menos devoluções. </li></ul>",
    },
    {
      q: "Qual a quantidade de consultas que cada empresa tem direito?",
      a: "<p>Cada empresa associada a GS1 Brasil, hoje, tem direito a consultar 30 códigos de barras (GTIN) por dia. Caso sua empresa precise de um volume maior de consultas, procure a <a href='mailto:atendimento@gs1br.org' style='display: inline' target='_blank'>GS1 Brasil</a>.</p> Caso já tenha solicitado, consulte na área de administrador.",
    },
  ]

  const[selectedIndex, setSelectedIndex] = useState(-1);

  const changeSelectedIndex = (id:number) =>
  {
    if(id !== selectedIndex)
    {
      setSelectedIndex(id);
    }
    else
    {
      setSelectedIndex(-1);
    }
  }

 

  return (

    <>
      <div className='container-fluid' >

        <div className="row">
          <div className="ms-webpart-zone ms-fullWidth" style={{width:"100%"}}>
            <div className="s4-wpcell-plain ms-webpartzone-cell ms-webpart-cell-vertical ms-fullWidth ">
              <div className="ms-webpart-chrome ms-webpart-chrome-vertical ms-webpart-chrome-fullWidth ">
                <div style={{width:"100%"}} className="ms-WPBody noindex">
                  <div id="ctl00_ctl59_g_2f5b81fd_9a2e_4a00_b943_f61e9ec60b26">
                    <div className="ms-rte-embedcode ms-rte-embedwp">
                      <div className="banner" style={{backgroundImage: `url("./filtrado.jpg")`}}>
                        <div className="container">
                          <div className="row">
                            <div className="col-12 col-md-8 py-5">
                              <div className="gs1-h1">Perguntas Frequentes</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

      <div className='wp-home'>

        <div className='container'>

          <div className='row bg-color-white py-5 px-4 rounded-lg'>

            <div className="col-12">
              <h3 className="title pb-3">
                ​​​Veja quais são as dúvidas mais comuns sobre o Verified by GS1
              </h3>
              <ul style={{padding:0, margin:0}} className="list-unstyled accordion" id="accordionFaq">
                {questions.map((question, index) => (
                  <AccordionFaq isTable={question.t} key={index} question={question.q} answer={question.a} id={index} active={selectedIndex === index} onClickQuestion={(id:number) => changeSelectedIndex(id)}/>
                ))}
              </ul>
            </div>

          </div>

        </div>

      </div>

    </>
    
    

  );

};

  
export default Faq;