import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import SearchOneGTIN from '../consultas/search_one/gtin';
import SearchOneGLN from '../consultas/search_one/gln';
import SearchOneOtherKeys from '../consultas/search_one/otherKeys';
import SearchManyGTIN from '../consultas/search_many/gtin';
import "./style.css"
import SearchManyGLN from '../consultas/search_many/gln';
import ModalChangeScreen from '../../components/ModalChangeScreen';
import { useSelector } from '../../native-state.dev';
import { act } from 'react-dom/test-utils';
import SearchManyOtherKeys from '../consultas/search_many/otherKeys';
import SearchOneFindCompany from '../consultas/search_one/findCompany';

interface ChooseSearchProps {
  handleTab: (tab: string) => void;
  activeTab: string;
  multiple: boolean;
}

const ChooseSearch: React.FC<ChooseSearchProps> = ({ handleTab, activeTab, multiple }) => {
  const [searchDone, setState] = useSelector((s: { searchDone: boolean }) => s.searchDone);

  const [isOpenModalRequest, setIsOpenModalRequest] = useState(false);
  const location = useLocation();
  const currentPath = location.pathname.split('/').pop(); 
  const navigate = useNavigate();

  const handleModalClick = (p0: string) => {
    if (searchDone) {
      setIsOpenModalRequest(true);
    }else{
      handleTab(p0)
    }
  };

  const handleChangeScreen = () => {
    setState({searchDone:false});

    setIsOpenModalRequest(false);
    if(activeTab==="tab1"){
      handleTab("tab2")
    }else if(activeTab==="tab2"){
      handleTab("tab3")
    }else if(activeTab==="tab3"){
      handleTab("tab4")
    }else{
      handleTab("tab1")
    }
  };

  const closeModal = () => setIsOpenModalRequest(false);
  const exportB = () => {
    setIsOpenModalRequest(false);
    const exportButton = document.getElementsByName("buttonExport")[0] as HTMLButtonElement | undefined;
    exportButton?.click();
  };
  return (
    <>
      <div className="row rowTitleApis">
        <div className="col-12 col-md-8 pt-md-0">
          <h1 className="gs1-h1 titlePrincipal" style={{ color: "#002c6c" }}>
            <img src="/check-green.png" className="imgcheck-green" />
            Verified by GS1
          </h1>
          <p className="titleDescriptionMin">
            Verifique a identidade de produtos com base em informações confiáveis de donos de marcas
          </p>
        </div>
      </div>
      

      <div className="bannerTotCustom">
        <div className="rowBoxApiCustoum bgBoxApi">
          <div className={activeTab === "tab1" ? "boxApiActiveCustom" : "boxApiCustom"} onClick={() => { handleModalClick("tab1"); }}>
            <label>
              <div className="boxContent">
                <img src="/gtin.svg" width="76" height="45px" className="boxIcon" />
                <h4 className="boxTitle custom-font-size " style={{ maxWidth: "160px" }}>Verificar Produto</h4>
                <p className="boxSubtitle">Busque através do número do código de barras (GTIN)</p>
              </div>
            </label>
          </div>

           <div className={activeTab === "tab2" ? "boxApiActiveCustom" : "boxApiCustom"} onClick={() => { handleModalClick("tab2"); }}>
            <label>
              <div className="boxContent">
                <img style={{ marginLeft: "-19px" }} src="/gln.svg" width="76" height="45px" className="boxIcon" />
                <h4 className="boxTitle custom-font-size ">Verificar Localização</h4>
                <p className="boxSubtitle">Busque através da chave de localização (GLN)</p>
              </div>
            </label>
          </div>

          <div className={activeTab === "tab3" ? "boxApiActiveCustom" : "boxApiCustom"} onClick={() => { handleModalClick("tab3"); }}>
            <label>
              <div className="boxContent">
                <img  src="/other-keys.svg" width="45" height="45px" className="boxIcon" />
                <h4 className="boxTitle custom-font-size " >Verificar Outras Chaves</h4>
                <p className="boxSubtitle">Busque por outras chaves</p>
              </div>
            </label>
          </div>

          {currentPath === 'homeApi' ? (
            <div
              className={activeTab === "tab4" ? "boxApiActiveCustom" : "boxApiCustom"}
              onClick={() => { handleModalClick("tab4"); }}
            >
              <label>
                <div className="boxContent">
                  <img
                    src="/company-name.svg"
                    width="45"
                    height="45px"
                    className="boxIcon"
                  />
                  <h4 className="boxTitle custom-font-size">Verificar Empresas</h4>
                  <p className="boxSubtitle">Pesquisar por nome da empresa e país</p>
                </div>
              </label>
            </div>
          ) : null}


        </div>
      </div>

      <div>
        {!multiple ?(
          activeTab === "tab1" ? (
            <SearchOneGTIN />
          ) : activeTab === "tab2" ? (
            <SearchOneGLN />
          ) : activeTab === "tab3" ? (
            <SearchOneOtherKeys />
          ): (
            <SearchOneFindCompany/>
          )
        ) 
        : (
          activeTab === "tab1" ?(
            <SearchManyGTIN /> 
          ) : activeTab === "tab2" ?(
            <SearchManyGLN /> 
          ): (
            <SearchManyOtherKeys/>
          )
        )
        }
      </div>

      <ModalChangeScreen
        isOpen={isOpenModalRequest}
        onClose={closeModal}
        onChangeScreen={handleChangeScreen}
        onExport={exportB}
      />
    </>
  );
};

export default ChooseSearch;
