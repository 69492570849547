

export const GRAIValidateKeyFunction = (value: string) => {
  let message: string = '';
  let titleMessage: string = '';
  let error: boolean = false;

  if (!/^[0-9]+$/.test(value)) {
    error = true;
    titleMessage = 'O código GRAI deve conter apenas caracteres numéricos.';
    message = 'Por favor, verifique se há erros de digitação.';
  }

  if (value.length < 13 || value.length > 29) {
    error = true;
    titleMessage = `O código GRAI deve ter entre 13 e 29 dígitos, mas o valor fornecido tem ${value.length} dígitos.`;
    message = '';
  } else {
  
    const position_value = [3, 1, 3, 1, 3, 1, 3, 1, 3, 1, 3, 1, 3]; 
    let grai_total = 0;
    let grai_positions = value.split("").slice(0, 13);
    let add_zero = 0;

    // Calcula o total ponderado
    for (let i = 0; i < grai_positions.length; i++) {
      grai_total += parseInt(grai_positions[i]) * position_value[i];
    }

  }

  return { error, titleMessage, message };
}

export const GCNValidateKeyFunction = (value: string) => {
    let message: string = '';
    let titleMessage: string = '';
    let error: boolean = false;
  
    // Verificação de comprimento: deve ter pelo menos 13 caracteres e no máximo 25 caracteres
    if (value.length < 13 || value.length > 25) {
      error = true;
      titleMessage = `O código GCN deve ter entre 13 e 25 caracteres, mas o valor fornecido tem ${value.length} caracteres.`;
      message = '';
      return { error, titleMessage, message };
    }
  
    // Verificação de caracteres permitidos (alfanuméricos e símbolos)
    if (!/^[\w\W]+$/.test(value)) {
      error = true;
      titleMessage = 'O código GCN deve conter apenas caracteres alfanuméricos e símbolos.';
      message = 'Por favor, verifique se há caracteres inválidos.';
      return { error, titleMessage, message };
    }
  
    // Cálculo do dígito verificador para os primeiros 12 caracteres
    const position_value = [3, 1, 3, 1, 3, 1, 3, 1, 3, 1, 3, 1]; // Padrão de multiplicadores
    let gcn_total = 0;
    const gcn_positions = value.substring(0, 12).split("");
  
    for (let i = 0; i < gcn_positions.length; i++) {
      let char_value: any = gcn_positions[i].toUpperCase();
      if (/[A-Z]/.test(char_value)) {
        char_value = char_value.charCodeAt(0) - 64; // Conversão de letra para número (A=1, B=2, ...)
      } else {
        char_value = parseInt(gcn_positions[i]);
      }
      gcn_total += char_value * position_value[i];
    }
  
    // Calcular múltiplo de 10 e determinar dígito verificador
    const multiple_10 = Math.ceil(gcn_total / 10) * 10;
    const check_digit = multiple_10 - gcn_total;
  
    // Verificar se o dígito verificador no 13º caractere é correto
    if (check_digit !== parseInt(value.charAt(12))) {
      error = true;
      titleMessage = `Dígito verificador incorreto. Você quis dizer ${value.slice(0, 12)}${check_digit}${value.slice(13)}?`;
      message = '';
    }
  
    return { error, titleMessage, message };
  }

export const GMNValidateKeyFunction = (value: string) => {
    let message: string = '';
  let titleMessage: string = '';
  let error: boolean = false;

  // GMN length should be between 23 and 25 characters
  if (value.length > 25) {
    error = true;
    titleMessage = `O código GMN deve ter no máximo 25 caracteres, mas o valor fornecido tem ${value.length} caracteres.`;
    message = '';
    return { error, titleMessage, message };
  }

  // Accepts any alphanumeric and special characters
  if (!/^[0-9A-Za-z\W_]+$/.test(value)) {
    error = true;
    titleMessage = 'O código GMN deve conter apenas caracteres alfanuméricos e símbolos permitidos.';
    message = 'Por favor, verifique se há caracteres inválidos.';
    return { error, titleMessage, message };
  }

  // Calculate the check character pair based on the first 23 characters
  const position_value = [3, 1, 3, 1, 3, 1, 3, 1, 3, 1, 3, 1, 3, 1, 3, 1, 3, 1, 3, 1, 3, 1, 3]; // Pattern for 23 positions
  let gmn_total = 0;
  const gmn_positions = value.substring(0, 23).split("");

  for (let i = 0; i < gmn_positions.length; i++) {
    let char_value: any = gmn_positions[i].toUpperCase();
    if (/[A-Z]/.test(char_value)) {
      char_value = char_value.charCodeAt(0) - 64; // Converts letters to A=1, B=2, ...
    } else {
      char_value = parseInt(gmn_positions[i]);
    }
    gmn_total += char_value * position_value[i];
  }


  return { error, titleMessage, message };
}

export const CPIDValidateKeyFunction = (value: string) => {
    let message: string = '';
    let titleMessage: string = '';
    let error: boolean = false;
  
    if (value.length > 30) {
      error = true;
      titleMessage = `O código CPID deve ter no máximo 30 caracteres, mas o valor fornecido tem ${value.length} caracteres.`;
      message = '';
    } 
    else if (!/^[\w\W]+$/.test(value)) {
      error = true;
      titleMessage = 'O código CPID deve conter apenas caracteres alfanuméricos e símbolos.';
      message = 'Por favor, verifique se há caracteres inválidos.';
    }
  
    return { error, titleMessage, message };
}

export const GSINValidateKeyFunction = (value: string) => {
    let position_number: number = 17;  
    let message: string = '';
    let titleMessage: string = '';
    let error: boolean = false;
  
    if (!/^[0-9]+$/.test(value)) {
        error = true;
        titleMessage = 'O número do código GSIN deve conter apenas caracteres numéricos.';
        message = 'Por favor, verifique se há erros de digitação.';
    }
  
    if (value.length !== position_number) {
      error = true;
      titleMessage = `O código de GSIN deve ter exatamente 17 dígitos para ser válido.`;
      message = '';
    } else {
      const position_value = [3, 1, 3, 1, 3, 1, 3, 1, 3, 1, 3, 1, 3, 1, 3, 1, 3]; 
      let gsin_total = 0;
      let gsin_positions = value.split("").slice(0, -1);  
      for (let i = 0; i < position_number - 1; i++) {
        gsin_total += parseInt(gsin_positions[i]) * position_value[i];
      }
  
      const multiple_10 = Math.ceil(gsin_total / 10) * 10;
  
      let check_digit = multiple_10 - gsin_total;
  
      if (check_digit === 10) {
        check_digit = 0;
      }
  
      
    }
  
    return { error, titleMessage, message };
}

export const GIAIValidateKeyFunction = (value: string) => {
    let message: string = '';
    let titleMessage: string = '';
    let error: boolean = false;
  
    // Verificação do comprimento máximo de 30 caracteres
    if (value.length > 30) {
      error = true;
      titleMessage = `O código GIAI deve ter no máximo 30 caracteres, mas o valor fornecido tem ${value.length} caracteres.`;
      message = '';
    } 
    // Verificação de caracteres permitidos (alfanuméricos e símbolos)
    else if (!/^[\w\W]+$/.test(value)) {
      error = true;
      titleMessage = 'O código GIAI deve conter apenas caracteres alfanuméricos e símbolos.';
      message = 'Por favor, verifique se há caracteres inválidos.';
    }
  
    return { error, titleMessage, message };
}

export const GINCValidateKeyFunction = (value: string) => {
  let message: string = '';
  let titleMessage: string = '';
  let error: boolean = false;

  // Verificação do comprimento máximo de 30 caracteres
  if (value.length > 30) {
    error = true;
    titleMessage = `O código GINC deve ter no máximo 30 caracteres, mas o valor fornecido tem ${value.length} caracteres.`;
    message = '';
  } 
  // Verificação de caracteres permitidos (alfanuméricos e símbolos)
  else if (!/^[\w\W]+$/.test(value)) {
    error = true;
    titleMessage = 'O código GINC deve conter apenas caracteres alfanuméricos e símbolos.';
    message = 'Por favor, verifique se há caracteres inválidos.';
  }

  return { error, titleMessage, message };
}

export const GDTIValidateKeyFunction = (value: string) => {
  let message: string = '';
  let titleMessage: string = '';
  let error: boolean = false;

  if (!/^[0-9A-Za-z\W_]+$/.test(value)) {
    error = true;
    titleMessage = 'O código GDTI deve conter apenas caracteres alfanuméricos (números e letras).';
    message = 'Por favor, verifique se há erros de digitação.';
  }

 
  if (value.length < 13 || value.length > 30) {
    error = true;
    titleMessage = `O código GDTI deve ter entre 13 e 30 caracteres, mas o valor fornecido tem ${value.length} caracteres.`;
    message = '';
  } else {
    
    const position_value = [3, 1, 3, 1, 3, 1, 3, 1, 3, 1, 3, 1, 3]; 
    let gdt_total = 0;
    let gdt_positions = value.split("").slice(0, 13);
    
    for (let i = 0; i < gdt_positions.length; i++) {
     
      let char_value: any = gdt_positions[i].toUpperCase();
      if (/[A-Z]/.test(char_value)) {
        char_value = char_value.charCodeAt(0) - 64; 
      } else {
        char_value = parseInt(gdt_positions[i]);
      }

      gdt_total += char_value * position_value[i]; 
    }
    
  }

     return { error, titleMessage, message };
}



export const SSCCValidateKeyFunction = (value: string) => {
  let position_number: number = 18; 
  let message: string = '';
  let titleMessage: string = '';
  let error: boolean = false;

  if(value.length != 18){
    error = true;
    titleMessage ='O código de SSCC deve ter no mínimo 18 dígitos para ser um código válido';
    message = '';
  }
  else if(!/^[0-9]+$/.test(value))
  {
      error = true;
      titleMessage = 'O número do código SSCC deve conter apenas caracteres numéricos.'
      message = 'Por favor, verifique se há erros de digitação'
  }

  if (value.length !== position_number) {
    error = true;
    titleMessage = `O código de SSCC deve ter exatamente 18 dígitos para ser válido.`;
    message = '';
  } else {
    const position_value = [3, 1, 3, 1, 3, 1, 3, 1, 3, 1, 3, 1, 3, 1, 3, 1, 3];
    let otherkeys_total = 0;
    let otherkeys_positions = value.split("").slice(0, -1);

    let add_zero = position_number - value.length;
    if (add_zero > 0) {
      for (let i = 0; i < add_zero; i++) {
        otherkeys_positions.unshift('0');
      }
    }

    for (let i = 0; i < position_number - 1; i++) {
      otherkeys_total += parseInt(otherkeys_positions[i]) * position_value[i];
    }
    const multiple_10 = Math.ceil(otherkeys_total / 10) * 10;
    const check_digit = multiple_10 - otherkeys_total;

    if (check_digit !== parseInt(value.substring(value.length - 1))) {
      error = true;
      titleMessage = `Dígito verificador incorreto. Você quis dizer ${value.slice(0, -1)}${check_digit}?`;
      message = '';
    }
  }

  return { error, titleMessage, message };
}

export const GSRNvalidateKeyFunction = (value: string) => {
    let position_number: number = 18; 
    let message: string = '';
    let titleMessage: string = '';
    let error: boolean = false;
  
     if(!/^[0-9]+$/.test(value))
    {
        error = true;
        titleMessage = 'O número do código GSRN deve conter apenas caracteres numéricos.'
        message = 'Por favor, verifique se há erros de digitação'
    }
    
    if (value.length !== position_number) {
      error = true;
      titleMessage = `O código de GRSN deve ter exatamente 18 dígitos para ser válido.`;
      message = '';
    } else {
      const position_value = [3, 1, 3, 1, 3, 1, 3, 1, 3, 1, 3, 1, 3, 1, 3, 1, 3];
      let otherkeys_total = 0;
      let otherkeys_positions = value.split("").slice(0, -1);
  
      let add_zero = position_number - value.length;
      if (add_zero > 0) {
        for (let i = 0; i < add_zero; i++) {
          otherkeys_positions.unshift('0');
        }
      }
  
      for (let i = 0; i < position_number - 1; i++) {
        otherkeys_total += parseInt(otherkeys_positions[i]) * position_value[i];
      }
      const multiple_10 = Math.ceil(otherkeys_total / 10) * 10;
      const check_digit = multiple_10 - otherkeys_total;
  
      if (check_digit !== parseInt(value.substring(value.length - 1))) {
        error = true;
        titleMessage = `Dígito verificador incorreto. Você quis dizer ${value.slice(0, -1)}${check_digit}?`;
        message = '';
      }
    }
  
    return { error, titleMessage, message };
  }