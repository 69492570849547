import React, { useState, useContext } from 'react';
import Modal from "react-modal";
import { IoMdClose } from 'react-icons/io';
import { getUnitName } from '../../../services/units';
import { exportFile } from "../../../services/exportFile";
import { LoginContext } from '../../../contexts/login_context';
import { StartRating } from '../../StarRating';
import Table from '../../Table';
import { showError, showWait, dismissToast, showSucess } from '../../../services/toast';

import Warning from '../../Warning';


const CompanyDetailGtin = ({ data, code,openContestation}: { data: any, code: any, openContestation:any }) => {



  return (
    <>
      <div className='row px-3 detailBox'>

        <div className='col-12 col-md-12 mb-5'>


          <div className='row'>
            <h3 className='titleCompanyDetail'>Informação sobre a empresa que licenciou este GTIN</h3>
          </div>
          <div className='row section pb-5'>

            <div className='col-12 col-md-12 table-responsive glnfntw'>
            {(data.vbg!= undefined) &&<>
              <table className='table'>
                <tbody>
                  {(data.vbg.gs1Licence.licenseeName != undefined) &&
                    <>
                      <tr>
                        <td className="propertyCompany borderbottom" style={{ border: 'none' }}>
                          Nome da empresa
                        </td>
                        <td className="propertyvalue borderbottom" style={{ border: 'none' }}>
                          {data.vbg.gs1Licence.licenseeName}
                        </td>
                      </tr>
                    </>}
                  
                      <tr>
                        <td className="propertyCompany borderbottom" style={{ border: 'none' }}>
                          Endereço
                        </td>
                        {(data.vbg.gs1Licence.address != undefined) &&
                        <>
                        <td className="propertyvalueCustom borderbottom sm:leading-none " style={{ border: 'none', lineHeight:'12px'}}>
                        {(data.vbg.gs1Licence.address.streetAddress != undefined) &&
                        <>
                          <p>{data.vbg.gs1Licence.address.streetAddress.value}</p>
                        </>}
                        {(data.vbg.gs1Licence.address.streetAddressLine2 != undefined) &&
                        <>
                          <p>{data.vbg.gs1Licence.address.streetAddressLine2.value}</p>
                        </>}
                        {(data.vbg.gs1Licence.address.addressLocality !=undefined) &&
                        <>
                          <p>{data.vbg.gs1Licence.address.addressLocality.value}</p>
                        </>
                        }
                        {(data.vbg.gs1Licence.address.addressRegion !=undefined) &&
                        <>
                          <p>{data.vbg.gs1Licence.address.addressRegion.value}</p>
                        </>
                        }
                        {(data.vbg.gs1Licence.address.postalCode !=undefined) &&
                        <>
                          <p>{data.vbg.gs1Licence.address.postalCode}</p>
                        </>
                        }
                        {(data.vbg.gs1Licence.address.countryName !=undefined) &&
                        <>
                          <p>{data.vbg.gs1Licence.address.countryName}</p>
                        </>
                        }
                        
                        
                        
                        </td>
                        </>}
                        {(data.vbg.gs1Licence.address == undefined) &&
                        <>
                        <td className="propertyvalue borderbottom" style={{ border: 'none'}}>
                         Não cadastrado
                        </td>
                        </>}
                      </tr>
                    
                    
                  
                      <tr>
                        <td className="propertyCompany borderbottom" style={{ border: 'none' }}>
                          Site
                        </td>
                        {(data.vbg.gs1Licence.contactPoint != undefined) &&
                        <>
                          <td className="propertyvalue borderbottom" style={{ border: 'none' }}>
                          {data.vbg.gs1Licence.contactPoint.map((txt: { website: any; }) =>
                            <div><a href={`${txt.website}`} >{txt.website}</a></div>
                          )}
                        </td>
                        </>}
                        {(data.vbg.gs1Licence.contactPoint == undefined) &&
                        <>
                          <td className="propertyvalue borderbottom" style={{ border: 'none' }}>
                          Não cadastrado
                        </td>
                        </>}
                      </tr>
                      
                  {(data.vbg.gs1Licence.licenceType != undefined) &&
                    <>
                      <tr>
                        <td className="propertyCompany borderbottom" style={{ border: 'none' }}>
                          Tipo de Prefixo
                        </td>
                        <td className="propertyvalue borderbottom" style={{ border: 'none' }}>
                          {data.vbg.gs1Licence.licenceType}
                        </td>
                      </tr>
                    </>}
                  {(data.vbg.gs1Licence.licenseeGLN != undefined) &&
                    <>
                      <tr>
                        <td className="propertyCompany borderbottom" style={{ border: 'none' }}>
                          Número Global de Localização (GLN)
                        </td>
                        <td className="propertyvalue borderbottom" style={{ border: 'none' }}>
                          {data.vbg.gs1Licence.licenseeGLN}
                        </td>
                      </tr>
                    </>}
                  {(data.vbg.gs1Licence.licensingMO != undefined) &&
                    <>
                      <tr>
                        <td className="propertyCompany borderbottom" style={{ border: 'none' }}>
                          Organização Membro GS1
                        </td>
                        <td className="propertyvalue borderbottom" style={{ border: 'none' }}>
                          {data.vbg.gs1Licence.licensingMO.moName}
                        </td>
                      </tr>
                    </>}
                </tbody>
              </table>
            </>}
            </div>
          </div>
          <div className='row  mb-2 pl-3 pr-3'>
            <span className="lineInfo">
              {data.vbg.licenseMessage}
            </span>
          </div>

        </div>
      </div >
    </>
  );

};

export default CompanyDetailGtin;
