import React, { useState, useRef  } from "react";
import CompanyDetailGtin from './CompanyDetailGtin';
import "./style.css";
import ProductDetailGtin from "./ProductDetailGtin";
import { ModalContestation } from "../ModalContestation";
import Warning from "../Warning";

const GtinResults = ({data, code}:{data: any, code:any}) => {
  const [isOpenModalContestation, setIsOpenModalContestation] = useState(false);

  function handleCloseModalContestation() {
    setIsOpenModalContestation(false)
  }


  const handleOpenModalContestation = () => {
    
    setIsOpenModalContestation(true)
  }
  const [activeTab, setActiveTab] = useState(data.vbg.containsProduct?"tab1":"tab2");

  const handleTab1 = () => {
    // update the state to tab1
    setActiveTab("tab1");
  };

  const handleTab2 = () => {
    // update the state to tab2
    setActiveTab("tab2");
  };

  const windowSize = useRef([window.innerWidth, window.innerHeight]);
 
  
  return (
    
    <div className="Tabs">
        <>
        <div className='' style={{ width: '100%' }}>
              {!data.messages.vbg.error && (
                <Warning                
                  showpick={data.vbg.hasError.showpic}
                  type={data.vbg.hasError.message_type}
                  title={data.messages.vbg.title}
                  text={data.messages.vbg.message}
                  handleCLick={handleOpenModalContestation}                        

                ></Warning>
              )}

              {data.messages.vbg.error && (
                <Warning
                  type='error'
                  showpick={data.vbg.hasError.showpic}
                  title={data.messages.vbg.title}
                  text={data.messages.vbg.message}

                ></Warning>
              )}

            </div>
        {data.vbg.gs1Licence!=="Desconhecido" &&(
          <>
           <ul className="nav">
            {data.vbg.containsProduct && (
              <li className={activeTab === "tab1" ? "active" : ""} onClick={handleTab1}>{windowSize.current[0]>600?'Informações do Produto':'Produto'}</li>
            )}
              
              <li className={activeTab === "tab2" ? "active" : ""} onClick={handleTab2}>{windowSize.current[0]>600?'Informações da Empresa':'Empresa'}</li>
            </ul>
            <div className="outlet">
              
              {
                  activeTab === "tab1" ? 
                      <ProductDetailGtin data={data} code={[code]} openContestation={handleOpenModalContestation}/>:
                      <CompanyDetailGtin data={data} code={[code]} openContestation={handleOpenModalContestation}/>
              }
            
            </div>
          </>
        )}
       
        </>
        <ModalContestation
                dadosGtin={data}
                isOpenModalContestation={isOpenModalContestation}
                handleCloseModalContestation={handleCloseModalContestation}
              />
    </div>
    
  );
};
export default GtinResults;

