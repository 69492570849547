import { useRef, useState } from "react";
import { AccordionGlnRecord } from "../../AccordionGlnRecord";
import { paintStatus, traduzirStatus, traduzirTextoFuncao, traduzirTipoFuncao } from "../../../services/gln_process";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { Tooltip } from "react-tooltip"

import './style.css'



const ProductDetailGln = ({ data }: { data: any }) => {
  const refsItem = useRef(new Array());
  const accordItens =
    data.productData.map((glrRecord: any, index: number) =>
      <AccordionGlnRecord ref={(element) => refsItem.current.push(element)} key={index} data={glrRecord} msgDate={data.msgDate} onClickItem={(index: any) => changeSelectedIndex(index)} props={undefined} />
    )


  const [selectedIndex, setSelectedIndex] = useState(-1);

  const changeSelectedIndex = (item: any) => {
    if (selectedIndex != item.tipoGlnTraduzido) {
      setSelectedIndex(item.tipoGlnTraduzido);
      refsItem.current.forEach(element => {
        element.close(item.tipoGlnTraduzido)
      })
    } else {
      setSelectedIndex(-1)
      refsItem.current.forEach(element => {
        element.close(-1)
      })
    }


  }

  const hasContact = (item: any) => {
    if (item != undefined && item != "Não cadastrado" && item.length > 0) {
      return true;
    }
    if (item == undefined || item == "Não cadastrado" || item.length == 0) {
      return false;
    }
    return false;
  };
  return (
    <>
      <div className='row px-3 detailBox'>
        {data.productData.length == 1 ?
          <>
            <div className='col-12 col-md-12 mb-5'>


              <h3 className='titleProductDetailGln'>{data.productData[0].nameTitle}</h3>
              <p className="color-ui-6 body-sm glnfntw" style={{ marginTop: "15px" }}>
                {traduzirTextoFuncao(data.productData[0].tipoGln, data.status == undefined ? data.productData[0].status : data.status)}
                    <span className={paintStatus(data.status == undefined ? data.productData[0].status : data.status)}>
                      {traduzirStatus(data.status == undefined ? data.productData[0].status : data.status)}
                      </span>
              </p>
              <div className='row section pb-5 glnfntw'>

                <div className='col-12 col-md-12 table-responsive'>
                  <table className='table'>
                    <tbody>

                      <tr>
                        <td className="propertyCompany borderbottom" style={{ border: 'none' }}>
                          GLN
                        </td>
                        <td className="propertyvalue borderbottom" style={{ border: 'none' }}>
                          {data.productData[0].gln}
                        </td>
                      </tr>
                      <tr>
                        <td className="propertyCompany borderbottom" style={{ border: 'none' }}>
                          Tipo de GLN <AiOutlineInfoCircle className="glntype" />

                          <Tooltip anchorSelect=".glntype" place="top">
                            Identificação de qual é o tipo de GLN utilizado (Entidade legal, Função, Localização física, Localização móvel ou Localização digital)
                          </Tooltip>
                        </td>
                        <td className="propertyvalue borderbottom" style={{ border: 'none' }}>
                          {data.productData[0].tipoGlnTraduzido}
                        </td>
                      </tr>

                      <tr>
                        <td className="propertyCompany borderbottom" style={{ border: 'none' }}>
                          {data.productData[0].textoNomeFuncao}
                        </td>
                        <td className="propertyvalue borderbottom" style={{ border: 'none' }}>
                          {data.productData[0].name}
                        </td>
                      </tr>

                      {/* {(data.productData[0].tipoGln != "PHYSICAL_LOCATION"
                        && data.productData[0].tipoGln != "FIXED_PHYSICAL_LOCATION"
                      )
                        && */}
                        <tr>
                          <td className="propertyCompany borderbottom" style={{ border: 'none' }}>
                            Entidade Relacionada <AiOutlineInfoCircle className="entity" />

                            <Tooltip anchorSelect=".entity" place="top">
                              Identifica qual é o GLN do tipo Entidade legal que se relaciona com o GLN buscado.
                            </Tooltip>
                          </td>
                          <td className="propertyvalue borderbottom" style={{ border: 'none' }}>
                            {data.productData[0].entidadeRelacionada}
                          </td>
                        </tr>
                      {/* } */}




                      <tr>
                        <td className="propertyCompany borderbottom" style={{ border: 'none' }}>
                          Área de atuação <AiOutlineInfoCircle className="areaatuacao" />
                          <Tooltip anchorSelect=".areaatuacao" place="top">
                            Funções e cargos relacionados ao GLN buscado
                          </Tooltip>
                        </td>
                        <td className="propertyvalue borderbottom" style={{ border: 'none' }}>
                          {data.productData[0].areaAtuacao}
                        </td>
                      </tr>


                      {
                        data.productData[0].tipoGln == "DIGITAL_LOCATION"
                        &&
                        <tr>
                          <td className="propertyCompany borderbottom" style={{ border: 'none' }}>
                            Endereço eletronico digital
                          </td>
                          <td className="propertyvalue borderbottom" style={{ border: 'none' }}>
                            {data.productData[0].digitalAddress}
                          </td>
                        </tr>
                      }

                      <tr>
                        <td className="propertyCompany borderbottom" style={{ border: 'none' }}>
                          Número de registro legal <AiOutlineInfoCircle className="registrolegal" />
                          <Tooltip anchorSelect=".registrolegal" place="top">
                            CNPJ (para pessoa juridica) ou CPF (para pessoa fisica) ao qual o GLN é relacionado
                          </Tooltip>
                        </td>
                        <td className="propertyvalue borderbottom" style={{ border: 'none' }}>
                          {data.productData[0].nroRegistroLegal}
                        </td>
                      </tr>
                     

                      <tr>
                        <td colSpan={2} style={{ border: 'none' }}>
                          <h3 style={{ fontSize: '18px' }}>Localização da empresa</h3>
                        </td>

                      </tr>



                      {
                        data.productData[0].tipoGln == "MOBILE_PHYSICAL_LOCATION"
                        &&
                        <tr>
                          <td className="propertyCompany borderbottom" style={{ border: 'none' }}>
                            Localização base (GLN)
                          </td>
                          <td className="propertyvalue borderbottom" style={{ border: 'none' }}>
                            {data.productData[0].baseLocation.gln}
                          </td>
                        </tr>
                      }
                      {
                        data.productData[0].tipoGln == "DIGITAL_LOCATION" &&
                        <>
                          <tr>
                            <td colSpan={2} style={{ border: 'none' }}>
                              <h3>Endereço digital</h3>
                            </td>
                          </tr>

                          <tr>
                            <td className="propertyCompany borderbottom" style={{ border: 'none' }}>
                              Telefone
                            </td>
                            <td className="propertyvalue borderbottom" style={{ border: 'none' }}>
                              {data.productData[0].enderecoDigital}
                            </td>
                          </tr>

                        </>
                      }
                      {data.productData[0].tipoGln != "DIGITAL_LOCATION" && data.productData[0].tipoGln != "MOBILE_PHYSICAL_LOCATION" &&

                        data.productData[0].endereco.map((endereco: any) =>

                          <>
                            <tr>
                              <td className="propertyCompany borderbottom" style={{ border: 'none' }}>
                                Endereço
                              </td>
                              {(endereco != "Não cadastrado") &&
                                <>
                                  <td className="propertyvalue borderbottom customColumns" style={{ border: 'none', lineHeight: '3px' }}>
                                    {(endereco.postalName != undefined) &&
                                      <>
                                        <p>{endereco.postalName.value}</p>
                                      </>}
                                    {(endereco.streetAddress != undefined) &&
                                      <>
                                        <p>{endereco.streetAddress.value}</p>
                                      </>}
                                    {(endereco.streetAddressLine2 != undefined) &&
                                      <>
                                        <p>{endereco.streetAddressLine2.value}</p>
                                      </>}
                                    {(endereco.addressLocality != undefined) &&
                                      <>
                                        <p>{endereco.addressLocality.value}</p>
                                      </>
                                    }
                                    {(endereco.addressRegion != undefined) &&
                                      <>
                                        <p>{endereco.addressRegion.value}</p>
                                      </>
                                    }
                                    {(endereco.postalCode != undefined) &&
                                      <>
                                        <p>{endereco.postalCode}</p>
                                      </>
                                    }
                                    {(endereco.countryCode != undefined) &&
                                      <>
                                        <p>{endereco.countryCode}</p>
                                      </>
                                    }

                                  </td>
                                </>}
                              {(endereco == "Não cadastrado") &&
                                <>
                                  <td className="propertyvalue borderbottom" style={{ border: 'none' }}>
                                    Não cadastrado
                                  </td>
                                </>}
                            </tr>

                          </>
                        )

                      }
                      {data.productData[0].geoShape
                        &&
                        <>
                          {

                            data.productData[0].geoShape.map((shape: any) =>

                              <>
                                <tr>
                                  <td colSpan={2} style={{ border: 'none' }}>
                                    <h3 style={{ fontSize: '18px' }}>Geoshape</h3>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="propertyCompany borderbottom" style={{ border: 'none' }}>
                                    Tipo
                                  </td>
                                  <td className="propertyvalue borderbottom" style={{ border: 'none' }}>
                                    {Object.keys(shape)[0]}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="propertyCompany borderbottom" style={{ border: 'none' }}>
                                    Cordenadas
                                  </td>
                                  <td className="propertyvalue borderbottom" style={{ border: 'none' }}>
                                    {shape[Object.keys(shape)[0]]}
                                  </td>
                                </tr>
                              </>
                            )
                          }



                        </>
                      }

                      {data.productData[0].geoCoordinates
                        &&
                        <>

                          <>
                            <tr>
                              <td colSpan={2} style={{ border: 'none' }}>
                                <h3 style={{ fontSize: '18px' }}>Geocordenadas</h3>
                              </td>
                            </tr>
                            <tr>
                              <td className="propertyCompany borderbottom" style={{ border: 'none' }}>
                                Latitude
                              </td>
                              <td className="propertyvalue borderbottom" style={{ border: 'none' }}>
                                {data.productData[0].geoCoordinates.latitude}
                              </td>
                            </tr>
                            <tr>
                              <td className="propertyCompany borderbottom" style={{ border: 'none' }}>
                                Longitude
                              </td>
                              <td className="propertyvalue borderbottom" style={{ border: 'none' }}>
                                {data.productData[0].geoCoordinates.longitude}
                              </td>
                            </tr>
                            <tr>
                              <td className="propertyCompany borderbottom" style={{ border: 'none' }}>
                                Elevação
                              </td>
                              <td className="propertyvalue borderbottom" style={{ border: 'none' }}>
                                {data.productData[0].geoCoordinates.elevation}
                              </td>
                            </tr>
                            <tr>
                              <td className="propertyCompany borderbottom" style={{ border: 'none' }}>
                                Sistema de coordenadas referência
                              </td>
                              <td className="propertyvalue borderbottom" style={{ border: 'none' }}>
                                {data.productData[0].geoCoordinates.coordinateReferenceSystem}
                              </td>
                            </tr>

                          </>
                        </>
                      }
                      {data.productData[0].containedInPlace
                      &&
                      <>
                        <tr>
                          <td className="propertyCompany borderbottom" style={{ border: 'none' }}>
                            Contido no endereço físico <AiOutlineInfoCircle className="contido"/>
                            
                            <Tooltip anchorSelect=".contido" place="top">
                            É o GLN de localização física que contém o GLN que foi buscado
                            </Tooltip>
                          
                          </td>
                          <td className="propertyvalue borderbottom" style={{ border: 'none' }}>
                            {data.productData[0].containedInPlace.gln+" "}                            
                            {"("+traduzirTipoFuncao(data.productData[0].containedInPlace.glnType)+")"}
                          </td>
                        </tr>
                      </>
                      }
                      <tr>
                        <td colSpan={2} style={{ border: 'none' }}>
                          <h3 style={{ fontSize: '18px' }}>Contatos</h3>
                        </td>
                      </tr>

                      <tr>
                        <td className="propertyCompany borderbottom" style={{ border: 'none' }}>
                          Email
                        </td>
                        <td className="propertyvalue borderbottom" style={{ border: 'none' }}>
                          {
                            data.productData[0].contatos.emails.map((item: any) => {
                              return <>
                                {item != "Não cadastrado" && <><a href={"mailto:" + item}>{item}</a><br></br></>}
                                {item == "Não cadastrado" && <>{item}<br></br></>}
                              </>
                            })
                          }
                        </td>
                      </tr>

                      <tr>
                        <td className="propertyCompany borderbottom" style={{ border: 'none' }}>
                          Telefone
                        </td>
                        <td className="propertyvalue borderbottom" style={{ border: 'none' }}>
                          {
                            data.productData[0].contatos.phones.map((item: any) => {
                              return <>{item}<br></br></>
                            })
                          }
                        </td>
                      </tr>

                      <tr>
                        <td className="propertyCompany borderbottom" style={{ border: 'none' }}>
                          Site
                        </td>
                        <td className="propertyvalue borderbottom" style={{ border: 'none' }}>
                          {
                            data.productData[0].contatos.sites.map((item: any) => {
                              return <>
                                {item != "Não cadastrado" && <><a href={item}>{item}</a><br></br></>}
                                {item == "Não cadastrado" && <>{item}<br></br></>}

                              </>
                            })
                          }
                        </td>
                      </tr>

                      <tr>
                        <td className="propertyCompany borderbottom" style={{ border: 'none' }}>
                          Idioma para contato
                        </td>
                        <td className="propertyvalue borderbottom" style={{ border: 'none' }}>
                          {
                            data.productData[0].contatos.idiomas.map((item: any) => {
                              return <>{item}<br></br></>
                            })
                          }
                        </td>
                      </tr>


                    </tbody>
                  </table>

                </div>
              </div>
              <div className='row  mb-2 pl-3 pr-3'>
                <span className="lineInfo">
                  {data.msgDate}
                </span>
              </div>

            </div>
          </> :
          <>
            <div className='col-12 col-md-12 mb-5'>
              <h3 className='titleProductDetailGln '>Este GLN contém as seguintes seções:</h3>
              <div className="marBtm">



                {accordItens}


              </div>
            </div>
          </>
        }
      </div >
    </>
  );

};

export default ProductDetailGln;