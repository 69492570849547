import { title } from 'process';
import { getCountryName } from './countryName';
import { getUnitName } from './units';
import { stat } from 'fs';
import { getLanguageName } from './languageName';

const unknown_text = 'Não cadastrado';
const unknown_item = [{ unknown: 'Não cadastrado' }];
const no_image_url = '/jam.png';

export function processValidationErrors(companiesData: any[], error: any) {
    for (var i = 0; i < companiesData.length; i++) {
        if (error.gln == companiesData[i].companyData.globalLocationNumber) {
            companiesData[i].errors = error;
            return companiesData
        }
    }
    companiesData.push(error)
    return companiesData;
}

export function processGLNData(data: any) {


    let retornoTotal = {};
    let companyData = {};


    companyData = mountCompanyInformation(data);
    let productData: {}[] = []
    if (!!data.glnRecords) {
        for (var i = 0; i < data.glnRecords.length; i++) {
            let productDataItem = mountProductInformation(data.glnRecords[i])
            productData.push(productDataItem);
        }
    }
    let contentMsg
  
    contentMsg = createErrorMsgStatus(data);
    let msgDate = undefined
    if((data.gs1Licence)&&(data.gs1Licence.licenseeName)){
        msgDate = createMsgDate(data);
    }

    retornoTotal = {
        "companyData": companyData,
        "productData": productData,
        "retornoMsg": contentMsg,
        "msgDate": msgDate
    }

    return retornoTotal;
}

function createMsgDate(data: any) {

    let _date = new Date(data.gs1Licence.dateUpdated);
    let _date2 = new Date(data.gs1Licence.dateCreated);
    let _date_format = _date.toLocaleDateString('pt-BR').split('/');
    let _date_format2 = _date2.toLocaleDateString('pt-BR').split('/');

    let months: Array<string> = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];

    let message_license = `Estas informações de licença foram fornecidas pela ${data.gs1Licence.licenseeName} em ${_date_format2[0]} de ${months[_date2.getMonth()]} de ${_date_format2[2]} e atualizadas pela última vez em ${_date_format[0]} de ${months[_date.getMonth()]} de ${_date_format[2]}.`;
    return message_license;

}
export function createErrorMsg(validationError: any) {
    let titleMsg = {}
   
        let vbg_error_code = 500;
        let vbg_show_pic = false;
        let vbg_title = `Erro ao buscar GLN`;
        let vbg_error_message = `O numero informado não pode ser encontrado na base de dados`
        titleMsg = {
            "vbg_error_code": vbg_error_code,
            "vbg_show_pic": vbg_show_pic,
            "show_content": false,
            "vbg_title": vbg_title,
            "vbg_error_message": vbg_error_message,
            "vbg_status": "error",
            "vbg_status_exportErrorvbg_status_exportError": true,
            "error": true,
            "showMSG": true
        }
    
    return titleMsg;
}

export function createErrorMsgStatus(data: any) {
        let titleMsg = {}
        let status = getStatus(data)
        let vbg_error_code = 500;
        let vbg_show_pic = false;
        let vbg_show_color = "success";
        let vbg_title = ``;
        let vbg_error_message = `Gln não encontrado.`
        if((data.gs1Licence)&&(data.gs1Licence.licenseeName)){
            vbg_error_message = `Este número está registrado na empresa <b><strong> ${data.gs1Licence.licenseeName}</strong></b>.`
        }
        
        if(status=="ACTIVE" || status=="OK"){
            vbg_error_code = 0;
            vbg_show_pic = true;
        }else{
        
            if(status=="INACTIVE"){
                vbg_show_color = "error"
                vbg_show_pic = false;
                
                vbg_error_message = `Este número está registrado na empresa <b><strong> ${data.gs1Licence.licenseeName}</strong></b>.  Este registro GLN está inativo. Este registro não está mais sendo atualizado.`

            }else{
                vbg_show_color = "warning"
                vbg_show_pic = true;
                vbg_error_message = `Este número está registrado na empresa <b><strong> ${data.gs1Licence.licenseeName}</strong></b>.  Este registro GLN está descontinuado. Este registro não está mais sendo atualizado.`

            }
        }
        
      
        titleMsg = {
            "vbg_error_code": vbg_error_code,
            "show_content": true,
            "vbg_show_pic": vbg_show_pic,
            "vbg_status": vbg_show_color,
            "vbg_status_exportError": false,
            "vbg_title": vbg_title,
            "vbg_error_message": vbg_error_message,
            "error": true,
            "showMSG": true
        }
    
    return titleMsg;
}

function getStatus(data: any){
    if(data.glnRecordStatus!=undefined){
        return data.glnRecordStatus
    }
    if(data.glnRecords!=undefined && data.glnRecords.length>0){
        if(data.glnRecords[0].glnRecordStatus!=undefined){
            return data.glnRecords[0].glnRecordStatus;
        }
    }
    return "ACTIVE";
}

function getValue(data: any, title: string) {
    if (data == undefined) {
        return "Não cadastrado";
    }
    if (!!data[title]) {
        return data[title]
    }

    return "Não cadastrado";
}
function validateCenarioB(data: any) {
    return (!data.glnRecords)
}
function validateCenarioC(data: any) {
    return (!!data.glnRecords && data.glnRecords.length == 1)
}
function validateCenarioD(data: any) {
    return (!!data.glnRecords && data.glnRecords.length > 1)
}


export function mountEnderecoArrayProduct(products:any[]){
    let enderecos: any[] =[]

    products.forEach(product=>{
        enderecos.push(product.endereco)
    })

    return enderecos;
}

export function mountEnderecoString(address: any) {

    let enderecoString =""

    if (!!address.postalName) {
        enderecoString += address.postalName.value + "\n";
    }
    if (!!address.streetAddress) {
        enderecoString += address.streetAddress.value + "\n";
    }
    if (!!address.streetAddressLine2) {
        enderecoString += address.streetAddressLine2.value + "\n";
    }
    if (!!address.addressLocality) {
        enderecoString += address.addressLocality.value + "\n";
    }

    if (!!address.addressSuburb) {
        enderecoString += address.addressSuburb.value + "\n";;
    }
   
    if (!!address.addressRegion) {
        enderecoString += address.addressRegion.value + "\n";
    }
    if (!!address.countryCode) {
        enderecoString += address.countryCode + "\n";
    }
    return enderecoString;
}

function mountEndereco(address: any) {
    let postalName = ""
    let countryCode = ""
    let addressRegion = ""
    let addressLocality = ""
    let streetAddress = ""
    let streetAddressLine2 = ""
    let addressSuburb = ""
    if (!!address.postalName) {
        postalName = address.postalName.value;
    }
    if (!!address.countryCode) {
        countryCode = address.countryCode;
    }
    if (!!address.addressRegion) {
        addressRegion = address.addressRegion.value;
    }
    if (!!address.addressLocality) {
        addressLocality = address.addressLocality.value;
    }
    if (!!address.streetAddress) {
        streetAddress = address.streetAddress.value;
    }
    if (!!address.streetAddressLine2) {
        streetAddressLine2 = address.streetAddressLine2.value;
    }
    if (!!address.addressSuburb) {
        addressSuburb = address.addressSuburb.value;
    }
    let enderecoFull = {
        "postalName": postalName,
        "countryCode": countryCode,
        "addressRegion": addressRegion,
        "addressLocality": addressLocality,
        "streetAddress": streetAddress,
        "streetAddressLine2": streetAddressLine2,
        "addressSuburb": addressSuburb
    }
    return enderecoFull;
}

function getCompanyAuxData(data: any) {
    if (validateCenarioB(data)) {
        return data.gs1Licence
    }
    if (validateCenarioC(data)) {
        return data.glnRecords[0]
    }
    if (validateCenarioD(data)) {
        return data.glnRecords[0]
    }
}

function mountCompanyInformation(data: any) {
    if (!data.gs1Licence) {
        return {};
    }
    let companyAux = data.gs1Licence;

    // companyAux = getCompanyAuxData(data);
    let contactPoint = getValue(companyAux, "contactPoint")

    let companyData = {}

    companyData = {
        "nomeEmpresa": getValue(data.gs1Licence, "licenseeName"),
        "endereco": Array.isArray(getValue(data.gs1Licence, "address")) ? getValue(data.gs1Licence, "address")[0] : getValue(data.gs1Licence, "address"),
        "site": getValue(contactPoint, "website"),
        "tipoPrefixo": getValue(data.gs1Licence, "licenceType")=="GCP"?"GS1 Company Prefix":getValue(data.gs1Licence, "licenceType"),
        "status": data.glnRecordStatus,
        "globalLocationNumber": getValue(data.gs1Licence, "licenceKey"),
        "gln": getValue(data, "gln"),
        "orgMenbroGs1": getValue(data.gs1Licence.licensingMO, "moName"),
        "dateCreated": getValue(companyAux, "dateCreated"),
        "dateUpdated": getValue(companyAux, "dateUpdated"),
        // "contatos": companyAux.contactPoint!=undefined? mountContatos(companyAux.contactPoint):"Não cadastrado"

    }
    return companyData;
}

export function traduzirStatus(status: string){
    if(status=="DISCONTINUED"){
        return "Descontinuada"
    }
    if(status=="INACTIVE"){
        return "Inativa"
    }
    return "Ativa"
}
export function paintStatus(status: string){
    if(status=="DISCONTINUED"){
        return "statusYellow"
    }
    if(status=="INACTIVE"){
        return "statusRed"
    }
    return "statusGreen"
}

export function traduzirTextoFuncao(glntype: string, status: string){
    if (glntype == "FUNCTION") {        
        return "Esta função esta: "
    }
    if (glntype == "LEGAL_ENTITY") {    
        return "Esta entidade legal esta: "
    }
    if (glntype == "DIGITAL_LOCATION") {
        return "Esta localização digital esta: "
    }
    if (glntype == "PHYSICAL_LOCATION") {
        return "Esta localização física esta: "
    }
    if (glntype == "FIXED_PHYSICAL_LOCATION") {
        return "Esta localização física esta: "
    }
    if (glntype == 'MOBILE_PHYSICAL_LOCATION') {
        return "Esta localização móvel esta: "
    }
}



function mountProductInformation(data: any) {
    let productData = {}

    let productAux = data
    let glntype = productAux.glnType
    if (glntype == "FUNCTION") {
        productData = {
            "geoCoordinates": productAux["geoCoordinates"],
            "geoShape": productAux["geoShape"],
            "gln": getValue(productAux, "gln"),
            "status": productAux.glnRecordStatus,
            "tipoGln": getValue(productAux, "glnType"),
            "baseLocation": productAux.baseLocation,
            "containedInPlace": productAux.containedInPlace,
            "digitalAddress": getValue(productAux, "digitalAddress"),
            "tipoGlnTraduzido": "Função",
            "textoNomeFuncao": "Nome da Função",
            "entidadeRelacionada": productAux.parentOrganization == undefined ? "Não cadastrado" : getValue(productAux.parentOrganization, "gln") + " (ENTIDADE LEGAL)",
            "areaAtuacao": (productAux.organizationRole!=undefined && productAux.organizationRole.length>0)? getValue(productAux, "organizationRole").join(', '):"Não cadastrado",
            "nroRegistroLegal": productAux.additionalOrganizationID == undefined ? "Não cadastrado" : getValue(productAux.additionalOrganizationID[0], "organizationID")+" ("+getValue(productAux.additionalOrganizationID[0], "organizationID_Type")+")",
            "nomeFuncao": productAux.organizationName == undefined ? "Não cadastrado" : getValue(productAux.organizationName[0], "value"),
            "enderecoDigital": getValue(productAux, "digitalAddress"),
            "endereco": getValue(productAux, "address"),
            "contatos": productAux.contactPoint!=undefined? mountContatos(productAux.contactPoint):mountContatos([]),
            "name": productAux.department==undefined?"Não cadastrado":"("+productAux.department[0].language+") " +productAux.department[0].value,
            "nameTitle": productAux.department==undefined?"Não cadastrado":productAux.department[0].value
        }
    }
    if (glntype == "LEGAL_ENTITY") {
        productData = {
            "geoCoordinates": productAux["geoCoordinates"],
            "geoShape": productAux["geoShape"],
            "gln": getValue(productAux, "gln"),
            "status": productAux.glnRecordStatus,
            "tipoGln": getValue(productAux, "glnType"),
            "baseLocation": productAux.baseLocation,
            "containedInPlace": productAux.containedInPlace,
            "digitalAddress": getValue(productAux, "digitalAddress"),
            "tipoGlnTraduzido": "Entidade legal",
            "textoNomeFuncao": "Nome da entidade legal",
            "entidadeRelacionada": productAux.parentOrganization == undefined ? "Não cadastrado" : getValue(productAux.parentOrganization, "gln") + " (ENTIDADE LEGAL)",
            "areaAtuacao": (productAux.organizationRole!=undefined && productAux.organizationRole.length>0)? getValue(productAux, "organizationRole").join(', '):"Não cadastrado",
            "nroRegistroLegal": productAux.additionalOrganizationID == undefined ? "Não cadastrado" : getValue(productAux.additionalOrganizationID[0], "organizationID")+" ("+getValue(productAux.additionalOrganizationID[0], "organizationID_Type")+")",
            "nomeFuncao": productAux.organizationName == undefined ? "Não cadastrado" : getValue(productAux.organizationName[0], "value"),
            "enderecoDigital": getValue(productAux, "digitalAddress"),
            "endereco": getValue(productAux, "address"),
            "contatos": productAux.contactPoint!=undefined? mountContatos(productAux.contactPoint):mountContatos([]),
            "name": productAux.organizationName==undefined?"Não cadastrado":"("+productAux.organizationName[0].language+") "+productAux.organizationName[0].value,
            "nameTitle": productAux.organizationName==undefined?"Não cadastrado":productAux.organizationName[0].value
        }
    }
    if (glntype == "DIGITAL_LOCATION") {
        productData = {
            "geoCoordinates": productAux["geoCoordinates"],
            "geoShape": productAux["geoShape"],
            "gln": getValue(productAux, "gln"),
            "status": productAux.glnRecordStatus,
            "tipoGln": getValue(productAux, "glnType"),
            "baseLocation": productAux.baseLocation,
            "containedInPlace": productAux.containedInPlace,
            "digitalAddress": getValue(productAux, "digitalAddress"),
            "tipoGlnTraduzido": "Localização digital",
            "textoNomeFuncao": "Nome da localização digital",
            "entidadeRelacionada": productAux.parentOrganization == undefined ? "Não cadastrado" : getValue(productAux.parentOrganization, "gln") + " (ENTIDADE LEGAL)",
            "areaAtuacao": (productAux.organizationRole!=undefined && productAux.organizationRole.length>0)? getValue(productAux, "organizationRole").join(', '):"Não cadastrado",
            "nroRegistroLegal": productAux.additionalLocationID == undefined ? "Não cadastrado" : getValue(productAux.additionalLocationID[0], "locationID") +" ("+getValue(productAux.additionalLocationID[0], "locationID_Type")+")",
            "nomeFuncao": productAux.organizationName == undefined ? "Não cadastrado" : getValue(productAux.organizationName[0], "value"),
            "enderecoDigital": getValue(productAux, "digitalAddress"),
            "endereco": getValue(productAux, "address"),
            "contatos": productAux.contactPoint!=undefined? mountContatos(productAux.contactPoint):mountContatos([]),
            "name": productAux.digitalLocationName==undefined?"Não cadastrado":"("+productAux.digitalLocationName[0].language+") "+productAux.digitalLocationName[0].value,
            "nameTitle": productAux.digitalLocationName==undefined?"Não cadastrado":productAux.digitalLocationName[0].value
        }
    }
    if (glntype == "PHYSICAL_LOCATION") {
        productData = {
            "geoCoordinates": productAux["geoCoordinates"],
            "geoShape": productAux["geoShape"],
            "gln": getValue(productAux, "gln"),
            "status": productAux.glnRecordStatus,
            "tipoGln": getValue(productAux, "glnType"),
            "baseLocation": productAux.baseLocation,
            "containedInPlace": productAux.containedInPlace,
            "digitalAddress": getValue(productAux, "digitalAddress"),
            "tipoGlnTraduzido": "Localização física",
            "textoNomeFuncao": "Nome da localização física",
            "entidadeRelacionada": productAux.parentOrganization == undefined ? "Não cadastrado" : getValue(productAux.parentOrganization[0], "gln") + " (ENTIDADE LEGAL)",
            "areaAtuacao": (productAux.locationRole!=undefined && productAux.locationRole.length>0)? getValue(productAux, "locationRole").join(', '):"Não cadastrado",
            "nroRegistroLegal": productAux.additionalLocationID == undefined ? "Não cadastrado" : getValue(productAux.additionalLocationID[0], "locationID") +" ("+getValue(productAux.additionalLocationID[0], "locationID_Type")+")",
            "nomeFuncao": productAux.organizationName == undefined ? "Não cadastrado" : getValue(productAux.organizationName[0], "value"),
            "enderecoDigital": getValue(productAux, "digitalAddress"),
            "endereco": getValue(productAux, "address"),
            "contatos": productAux.contactPoint!=undefined? mountContatos(productAux.contactPoint):mountContatos([]),
            "name": productAux.physicalLocationName==undefined?"Não cadastrado":"("+productAux.physicalLocationName[0].language+") "+productAux.physicalLocationName[0].value,
            "nameTitle": productAux.physicalLocationName==undefined?"Não cadastrado":productAux.physicalLocationName[0].value
        }
    }
    if (glntype == "FIXED_PHYSICAL_LOCATION") {
        productData = {
            "geoCoordinates": productAux["geoCoordinates"],
            "geoShape": productAux["geoShape"],
            "gln": getValue(productAux, "gln"),
            "status": productAux.glnRecordStatus,
            "tipoGln": getValue(productAux, "glnType"),
            "baseLocation": productAux.baseLocation,
            "containedInPlace": productAux.containedInPlace,
            "digitalAddress": getValue(productAux, "digitalAddress"),
            "tipoGlnTraduzido": "Localização física",
            "textoNomeFuncao": "Nome da localização física",
            "entidadeRelacionada": productAux.parentOrganization == undefined ? "Não cadastrado" : getValue(productAux.parentOrganization[0], "gln") + " (ENTIDADE LEGAL)",
            "areaAtuacao": (productAux.locationRole!=undefined && productAux.locationRole.length>0)? getValue(productAux, "locationRole").join(', '):"Não cadastrado",
            "nroRegistroLegal": productAux.additionalLocationID == undefined ? "Não cadastrado" : getValue(productAux.additionalLocationID[0], "locationID") +" ("+getValue(productAux.additionalLocationID[0], "locationID_Type")+")",
            "nomeFuncao": productAux.organizationName == undefined ? "Não cadastrado" : getValue(productAux.organizationName[0], "value"),
            "enderecoDigital": getValue(productAux, "digitalAddress"),
            "endereco": getValue(productAux, "address"),
            "contatos": productAux.contactPoint!=undefined? mountContatos(productAux.contactPoint):mountContatos([]),
            "name": productAux.physicalLocationName==undefined?"Não cadastrado":"("+productAux.physicalLocationName[0].language+") "+productAux.physicalLocationName[0].value,
            "nameTitle": productAux.physicalLocationName==undefined?"Não cadastrado":productAux.physicalLocationName[0].value
        }
    }
    if (glntype == 'MOBILE_PHYSICAL_LOCATION') {
        productData = {
            "geoCoordinates": productAux["geoCoordinates"],
            "geoShape": productAux["geoShape"],
            "gln": getValue(productAux, "gln"),
            "status": productAux.glnRecordStatus,
            "tipoGln": getValue(productAux, "glnType"),
            "baseLocation": productAux.baseLocation,
            "containedInPlace": productAux.containedInPlace,
            "digitalAddress": getValue(productAux, "digitalAddress"),
            "tipoGlnTraduzido": "Localização móvel",
            "textoNomeFuncao": "Nome da localização móvel",
            "entidadeRelacionada": productAux.parentOrganization == undefined ? "Não cadastrado" : getValue(productAux.parentOrganization, "gln") + " (ENTIDADE LEGAL)",
            "areaAtuacao": (productAux.locationRole!=undefined && productAux.locationRole.length>0)? getValue(productAux, "locationRole").join(', '):"Não cadastrado",
            "nroRegistroLegal": productAux.additionalLocationID == undefined ? "Não cadastrado" : getValue(productAux.additionalLocationID[0], "locationID") +" ("+getValue(productAux.additionalLocationID[0], "locationID_Type")+")",
            "nomeFuncao": productAux.organizationName == undefined ? "Não cadastrado" : getValue(productAux.organizationName[0], "value"),
            "enderecoDigital": getValue(productAux, "digitalAddress"),
            "endereco": getValue(productAux, "address"),
            "contatos": productAux.contactPoint!=undefined? mountContatos(productAux.contactPoint):mountContatos([]),
            "name": productAux.physicalLocationName==undefined?"Não cadastrado":"("+productAux.physicalLocationName[0].language+") "+productAux.physicalLocationName[0].value,
            "nameTitle": productAux.physicalLocationName==undefined?"Não cadastrado":productAux.physicalLocationName[0].value
        }
    }




    return productData;

}

function mountContatos(contactPoint: any) {
    let emails: any[]=[]
    let phones: any[]=[]
    let sites: any[]=[]
    let idiomas: any[]=[]
    contactPoint.forEach((item: any) => {
        if(!!item.email){
            emails.push(item.email);
        }
        if(!!item.telephone){
            phones.push(item.telephone);
        }
        if(!!item.website){
            sites.push(item.website);
        }
        if(!!item.availableLanguage){
            item.availableLanguage.forEach((sigla:any)=>{    
                let languageName = getLanguageName(
                    sigla
                )
                idiomas.push("("+sigla+")" +" "+ languageName);
            })
        }
    });
    if(emails.length==0){
        emails.push("Não cadastrado")
    }
    if(sites.length==0){
        sites.push("Não cadastrado")
    }
    if(phones.length==0){
        phones.push("Não cadastrado")
    }
    if(idiomas.length==0){
        idiomas.push("Não cadastrado")
    }
    return {
        'emails':emails,
        'phones':phones,
        'sites':sites,
        'idiomas':idiomas,

    }
}


export function traduzirTipoFuncao(glntype: string){
    if (glntype == "FUNCTION") {
        return "Função"
    }
    if (glntype == "LEGAL_ENTITY") {
        return "Entidade legal"
    }
    if (glntype == "DIGITAL_LOCATION") {
        return "Localização digital"    }
    if (glntype == "PHYSICAL_LOCATION") {
        return "Localização física"
    }
    if (glntype == "FIXED_PHYSICAL_LOCATION") {
        return "Localização física"
    }
    if (glntype == 'MOBILE_PHYSICAL_LOCATION') {
        return "Localização móvel"
    }
}


