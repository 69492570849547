import { getConfig } from "../config/config";
import * as xlsx from "xlsx"
import * as FileSaver from "file-saver";
import { getCountryName } from './countryName';
import { traduzirTipoFuncao } from "./gln_process";


export function exportFileTestGln(dataPrepared:any, filetype:string, numberMaxProduct:number){
  let headers =[]
  headers.push("Nome da Empresa");
  headers.push("Endereço");
  headers.push("Site");
  headers.push("Tipo de Prefixo");
  headers.push("GLN");
  headers.push("Organização Membro GS1");
  for (let index = 1; index <= numberMaxProduct; index++) {
    headers.push("gln_"+index+"_GLN");
    headers.push("gln_"+index+"_Tipo de GLN");
    headers.push("gln_"+index+"_Entidade Relacionada");
    headers.push("gln_"+index+"_Area de Atuacao");
    headers.push("gln_"+index+"_Numero de registro legal");
    headers.push("gln_"+index+"_enderecoDigital");
    headers.push("gln_"+index+"_endereco");
    headers.push("gln_"+index+"emails");
    headers.push("gln_"+index+"phones");
    headers.push("gln_"+index+"sites");
  }
 
  headers.push("Erro");

  let ws;
  if(numberMaxProduct>0)
    ws =xlsx.utils.json_to_sheet(dataPrepared,{header:headers});
  else
    ws =xlsx.utils.json_to_sheet(dataPrepared);

  
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  const excelBuffer = xlsx.write(wb, { bookType: filetype=="xls"?"xls":"csv", type: "array" });
  const data = new Blob([excelBuffer]);
  FileSaver.saveAs(data, "arquivo" + "."+filetype);
}


function concatenateEndereco(address:any){
  let retorno ="";
  if(!!address){
    if (!!address.postalName) {
        retorno += address.postalName.value;
      }
      if (!!address.countryCode) {
        retorno += address.countryCode;
      }
      if (!!address.addressRegion) {
        retorno += address.addressRegion.value;
      }
      if (!!address.addressLocality) {
        retorno += address.addressLocality.value;
      }
      if (!!address.streetAddress) {
        retorno += address.streetAddress.value;
      }
      if (!!address.streetAddressLine2) {
        retorno += address.streetAddressLine2.value;
      }
      if (!!address.addressSuburb) {
        retorno += address.addressSuburb.value;
      }
    }
    return retorno
}




function mountProcutData(product:any, index:number){
  let productData = {}
  let endData = {}
  let geoData = {}

    productData = {
        ["gln_"+index+"_GLN"]: product.gln,
        ["gln_"+index+"_Tipo de GLN"]: product.tipoGlnTraduzido,
        ["gln_"+index+"_Entidade Relacionada"]: product.entidadeRelacionada,
        ["gln_"+index+"_Area de Atuacao"]: product.areaAtuacao,
        ["gln_"+index+"_Numero de registro legal"]: product.nroRegistroLegal,
        ["gln_"+index+"_enderecoDigital"]: product.enderecoDigital,
        ["gln_"+index+"_emails"]: product.contatos.emails.toString(),
        ["gln_"+index+"_phones"]: product.contatos.phones.toString(),
        ["gln_"+index+"_sites"]: product.contatos.sites.toString(),
        ["gln_"+index+"_contido no endereco"]: product.containedInPlace?product.containedInPlace.gln+" " + traduzirTipoFuncao(product.containedInPlace.glnType):"Não Cadastrado",
        ["gln_"+index+"_latitude"]: product.geoCoordinates?product.geoCoordinates.latitude:"Não Cadastrado",
        ["gln_"+index+"_longitude"]: product.geoCoordinates?product.geoCoordinates.longitude:"Não Cadastrado",
        ["gln_"+index+"_elevation"]: product.geoCoordinates?product.geoCoordinates.elevation:"Não Cadastrado",
        ["gln_"+index+"_coordinateReferenceSystem"]: product.geoCoordinates?product.geoCoordinates.coordinateReferenceSystem:"Não Cadastrado",
        
    }
    if(product.endereco){
      if(Array.isArray(product.endereco)){
        for (let ip = 0; ip < product.endereco.length; ip++) {
          endData = merge(endData, {["gln_"+index+"_endereco_"+(ip+1)]: concatenateEndereco(product.endereco[ip])})
        }
      }else{
        endData = {
          ["gln_"+index+"_endereco_1"]: concatenateEndereco(product.endereco),
        }
      }
    }
    if(product.geoShape){
      if(Array.isArray(product.geoShape)){
        for (let ig = 0; ig < product.geoShape.length; ig++) {
          geoData = merge(
            geoData,
                {
                  ["gln_"+index+"_geoshape_"+(ig+1)+"_tipo"]: Object.keys(product.geoShape[ig])[0],
                  ["gln_"+index+"_geoshape_"+(ig+1)+"_coordenadas"]: product.geoShape[ig][Object.keys(product.geoShape[ig])[0]],
                }
          )
        }
      }else{
        geoData = {
          ["gln_"+index+"_geoshape_1_tipo"]: Object.keys(product.geoShape)[0],
          ["gln_"+index+"_geoshape_1_coordenadas"]: product.geoShape[Object.keys(product.geoShape)[0]],
                
        }
      }
    }

    return merge(merge(productData,endData),geoData)
}


export function exportFileFrontEndGLNError(data:any){

  let item={
    'GLN': data.gln, 
    'Erro': data.geral?data.geral[0].titulo:"Não foi possível encontrar este gln" 
  }
   
  return item;
}

export function exportFileFrontEndGLN(data:any){

  let productData: any={}


  if(!!data.productData)
    for (let index = 0; index < data.productData.length; index++) {
      let aux = mountProcutData(data.productData[index], index+1)
      let merged = merge(productData,aux)
      productData = merged
      
    }

  

  let item={
    'Nome da Empresa': data.companyData.nomeEmpresa, 
    'Endereço': concatenateEndereco(data.companyData.endereco), 
    'Site': data.companyData.site, 
    'Tipo de Prefixo': data.companyData.tipoPrefixo, 
    'GLN': data.companyData.gln, 
    'Organização Membro GS1': data.companyData.orgMenbroGs1 ,
    'Erro': data.retornoMsg.vbg_status!="success"?data.retornoMsg.vbg_status_exportError?data.retornoMsg.vbg_error_message:"":""    
  }
   
  return merge(productData,item);
}

function merge(obj1: any, obj2: any) {
  let merged = {
    ...obj1,
    ...obj2
  };
  Object.keys(obj1).filter(k => obj2.hasOwnProperty(k)).forEach((k) => {
    merged[k] = obj1[k] + "," + obj2[k]
  })
  return merged
}
