import { link } from 'fs';
import { getCountryName } from './countryName';
import { getUnitName } from './units';

const unknown_text = 'Desconhecido';  
const unknown_item = [{unknown:'Desconhecido'}];  
const no_image_url = '/jam.png';


export function processGTINData(response:any) 
{    
    let gepir_message:string = 'Válido';
    let gepir_error_code:number = 0;

    let flagcnp:string = unknown_text;
    let countrycnp: string = '';
    let cnp_error_code:number = 0;
    let cnp_error_message:string = unknown_text;
    let cnp_show_pic:boolean = false;
    let cnp_title:string = unknown_text;
    let additionalCNPUrl:any = [];

    let flagvbg:string = unknown_text;
    let countryvbg: string = '';
    let vbg_error_code:number = 0;
    let vbg_error_message:string = unknown_text;
    let vbg_show_pic:boolean = false;
    let vbg_title:string = unknown_text;
    let vbg_message_license:string = '';
    let vbg_message_license_product:string = '';
    let vbg_gtin_record_status_message: string = '';
    let statusFinal: string = '';
    let titleFinal: string = '';
    let msgFinal: string = '';
    let completoFinal: string = '';

    let cnp_messages: any;
    let vbg_messages: any;
    let gepir_messages: any;
    let gepir_visible: true;
    let geral_messages: any;

    let months:Array<string> = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];
   
    
    let vbgpic = (response.VBG != null) 
    ? (response.VBG.productImageUrl)
    ? response.VBG.productImageUrl[0].value 
    : no_image_url
    : unknown_text;
  
    let cnppic = (response.CNP != null)
    ? (Object.keys(response.CNP).length > 0)
    ? (response.CNP.product != null)
    ? (response.CNP.product.referencedFileInformations[0]) 
    ? response.CNP.product.referencedFileInformations[0].uniformResourceIdentifier 
    : no_image_url
    : unknown_text
    : unknown_text
    : unknown_text;

    if(response.CNP != null)
    {
        if(Object.keys(response.CNP).length > 0)
        {
            if(response.CNP.product != null)
            {
                if(response.CNP.product.referencedFileInformations.length > 1)
                {
                    for(let i = 1; i <= response.CNP.product.referencedFileInformations.length - 1 ;  i++)
                    {
                        additionalCNPUrl.push(response.CNP.product.referencedFileInformations[i].uniformResourceIdentifier);
                    }
                }
            }
        }
    }

    let linkset=undefined;
    if(response.VBG && response.VBG.linkset){
        let links: (any)[]=[]
        Object.keys(response.VBG.linkset).map((key,value) => {    
            if(Array.isArray(response.VBG.linkset[key])){
                let linksInside: (any)[]=[]
                let referencedFileType: {} | undefined = {};
                Object.values(response.VBG.linkset[key]).map((item:any, index) => {                     
                    if('href' in item){
                        linksInside.push(response.VBG.linkset[key][index])                       
                    }else{
                        referencedFileType = response.VBG.linkset[key][index]
                    }
                })
                if (Object.keys(referencedFileType).length === 0) {
                    referencedFileType = undefined;
                }
                let link ={
                    'key':key,
                    'links':linksInside,
                    'referencedFileType':referencedFileType
                }
                links.push(link)
            }
        })
        linkset={
            itemDescription : response.VBG.linkset.itemDescription,
            anchor: response.VBG.linkset.anchor,
            links: links
        }
    }

    if(response.VBG )
        if(response.VBG.brandName ==undefined){
            response.mensagens.vbg[0].mensagem=response.mensagens.vbg[0].mensagem.replace(" Envie seu Feedback.", "")
        }
    cnp_messages = (response.mensagens && response.mensagens.cnp && response.mensagens.cnp !== undefined) ? response.mensagens.cnp[0] : null;
    gepir_messages = (response.mensagens && response.mensagens.gepir && response.mensagens.gepir !== undefined && response.mensagens.gepir.length > 0) ? response.mensagens.gepir[0] : null ;
    vbg_messages = (response.mensagens && response.mensagens.vbg && response.mensagens.vbg !== undefined && response.mensagens.vbg.length > 0) ? response.mensagens.vbg[0] : null;
    geral_messages = (response.mensagens && response.mensagens.geral && response.mensagens.geral !== undefined && response.mensagens.geral.length > 0) ? response.mensagens.geral[0] : null;


    if(response.VBG && Object.keys(response.VBG).length!=0)
    {
        if(response.VBG.dateUpdated && response.VBG.licenseeName)
        {
            let _date = new Date(response.VBG.dateUpdated);
            let _date2 = new Date(response.VBG.dateCreated);
            let _date_format = _date.toLocaleDateString('pt-BR').split('/');
            let _date_format2 = _date2.toLocaleDateString('pt-BR').split('/');
            vbg_message_license = `Estas informações de licença foram fornecidas pela ${response.VBG.gs1Licence.licensingMO.moName} em ${_date_format2[0]} de ${months[_date2.getMonth()]} de ${_date_format2[2]} e atualizadas pela última vez em ${_date_format[0]} de ${months[_date.getMonth()]} de ${_date_format[2]}.`;
            vbg_message_license_product = `Estes dados foram disponibilizados por ${response.VBG.gs1Licence.licensingMO.moName} e sua última atualização foi em ${_date_format[0]} de ${months[_date.getMonth()]} de ${_date_format[2]}.`;
        }else{
            if(response.VBG.gs1Licence && response.VBG.gs1Licence.dateUpdated && response.VBG.gs1Licence.licenseeName){
                let _date = new Date(response.VBG.gs1Licence.dateUpdated);
                let _date2 = new Date(response.VBG.gs1Licence.dateCreated);
                let _date_format = _date.toLocaleDateString('pt-BR').split('/');
                let _date_format2 = _date2.toLocaleDateString('pt-BR').split('/');
                vbg_message_license = `Estas informações de licença foram fornecidas pela ${response.VBG.gs1Licence.licensingMO.moName} em ${_date_format2[0]} de ${months[_date2.getMonth()]} de ${_date_format2[2]} e atualizadas pela última vez em ${_date_format[0]} de ${months[_date.getMonth()]} de ${_date_format[2]}.`;
                vbg_message_license_product = `Estes dados foram disponibilizados por ${response.VBG.gs1Licence.licensingMO.moName} e sua última atualização foi em ${_date_format[0]} de ${months[_date.getMonth()]} de ${_date_format[2]}.`;
            }
        }
        if(response.VBG.gs1Licence && response.VBG.gs1Licence.address){
            if(response.VBG.gs1Licence.address.countryCode){
                response.VBG.gs1Licence.address.countryName= getCountryName(
                    response.VBG.gs1Licence.address.countryCode
                )
           }
        }
        
        

        if(response.VBG.gtinRecordStatus === 'INACTIVE')
        {
            vbg_gtin_record_status_message = `Este GTIN está inativo. Este GTIN não está mais sendo mantido atualizado pelo dono da marca. Caso precise de ajuda, entre em contato com a GS1 Brasil.`;
        }
        if(response.VBG.gtinRecordStatus === 'DISCONTINUED')
        {
            vbg_gtin_record_status_message = `Este GTIN está descontinuado. Este GTIN não está mais sendo mantido atualizado pelo dono da marca. Caso precise de ajuda, entre em contato com a GS1 Brasil.`;
        }
        statusFinal = response.VBG.gtinRecordStatus
        if(response.VBG.countryOfSaleCode)
        {
            if(response.VBG.countryOfSaleCode[0].alpha2){
                flagvbg = `/flags/${response.VBG.countryOfSaleCode[0].alpha2.toLowerCase()}.png`;
                countryvbg = `, ${getCountryName(response.VBG.countryOfSaleCode[0].alpha2)}`;
            }
        }

        if(response.VBG.isComplete === true)
        {
            vbg_error_code = 0;
            vbg_show_pic = true;
            vbg_title = 'Os dados do produto estão completos';
            vbg_error_message = `Este número está registrado na empresa ${response.VBG.licenseeName==undefined?response.VBG.gs1Licence.licenseeName:response.VBG.licenseeName}`
        }
        else if(response.VBG.isComplete === false)
        {
            vbg_error_code = 0;
            vbg_show_pic = false;
            vbg_title = 'Dados incompletos';
            vbg_error_message = `Este número está registrado na empresa ${response.VBG.licenseeName==undefined?response.VBG.gs1Licence.licenseeName:response.VBG.licenseeName}, e alguns atributos do produto estão disponíveis.`
        }
        else if(!response.VBG.isComplete && response.VBG.gs1Licence)
        {
            vbg_error_code = 1;
            vbg_title = '';
            vbg_show_pic = false;
            vbg_error_message = `Este número está registrado na empresa ${response.VBG.licenseeName==undefined?response.VBG.gs1Licence.licenseeName:response.VBG.licenseeName}`
        }
        else
        {
            vbg_error_code = 1;
            vbg_title = '';
            vbg_show_pic = false;
            vbg_error_message = `Código de barras (GTIN) não disponível no Verified by GS1.`
        }

        if(response.VBG.code)
        {
            vbg_error_code = 1;
            vbg_title = '';
            vbg_show_pic = false;
            vbg_error_message = `Código de barras (GTIN) não disponível no Verified by GS1.`
        }
        
    }
    else
    {
        vbg_error_code = 1;
        vbg_title = '';
        vbg_show_pic = false;
        vbg_error_message = `Código de barras (GTIN) não disponível no Verified by GS1.`
    }

    if(response.CNP)
    {
        if(response.CNP.product)
        {
            if(response.CNP.product.brandNameInformationLang && response.CNP.product.brandNameInformationLang.length > 0)
            {
                let get_country_code = response.CNP.product.brandNameInformationLang[0].languageCode.split('-');
                flagcnp = (get_country_code.length === 2) ? `/flags/${get_country_code[1].toLowerCase()}.png` : `/flags/${response.CNP.product.brandNameInformationLang[0].languageCode}.png`;

                countrycnp =(get_country_code.length === 2) ?`, ${getCountryName(get_country_code[1])}` : `, ${getCountryName(response.CNP.product.brandNameInformationLang[0].languageCode)}`;
            }
        }

        if(response.CNP.status === 'Inválido' && response.CNP.message === 'GTIN não encontrado.' && response.VBG != null)
        {
            cnp_error_code = 1;
            cnp_show_pic = false;
            cnp_title = '';
            cnp_error_message = `O número do código de barras (GTIN) é internacional, portanto, não está disponível no Cadastro Nacional de Produtos.`;
        }
        else if(response.CNP.status === 'Inválido' && response.VBG  && response.VBG.code === 5)
        {
            cnp_error_code = 1;
            cnp_show_pic = false;
            cnp_title = '';
            cnp_error_message = response.CNP.message;
        }
        else if(response.CNP.status === 'Inválido' && !response.CNP.product)
        {
            cnp_error_code = 1;
            cnp_show_pic = false;
            cnp_title = '';
            cnp_error_message = response.CNP.message;
        }
        else if(!response.CNP.product)
        {
            cnp_error_code = 1;
            cnp_show_pic = false;
            cnp_title = '';
            cnp_error_message = `O número do código de barras (GTIN) não está disponível no Cadastro Nacional de Produtos.`;
        }
    }
    else
    {
        cnp_error_code = 1;
        cnp_show_pic = false;
        cnp_title = '';
        cnp_error_message = `O número do código de barras (GTIN) não está disponível no Cadastro Nacional de Produtos.`;
    }


    if( response.GEPIR && response.GEPIR.returnCode )
    {
        if(response.GEPIR.returnCode === '0' || response.GEPIR.returnCode === '3')
        {
            gepir_message = 'Válido';
            gepir_error_code = 0;
        }
        else if(response.GEPIR.returnCode === '2' || response.GEPIR.returnCode === '7')
        {
            gepir_message = 'Informação não disponível';
            gepir_error_code = 1;
        }
        else if(response.GEPIR.returnCode === '16' || response.GEPIR.returnCode === '17')
        {
            vbg_error_code = 1;
            cnp_error_code = 2029200299; //código para range 020-029 e 200-299 (apenas para visualização interna de produtos)
            vbg_title = '';
            vbg_show_pic = false;
            vbg_error_message = `Código de barras (GTIN) não disponível. Códigos com início 020-029 e 200-299 são para uso interno das empresas.`
        }
        else
        {
            gepir_message = 'Inválido';
            gepir_error_code = 1;
        }

    }
    else if(response.GEPIR)
    {
        if(response.GEPIR.status === 'Válido')
        {
            gepir_message = 'Válido';
            gepir_error_code = 0; 
        }
        else
        {
            gepir_message = 'Inválido';
            gepir_error_code = 1;
        }
    }
    else
    {
        gepir_message = 'Inválido';
        gepir_error_code = 1;
    } 
    let countriesComplete: { sigla: any; name: string; }[] =[]
    if (response.VBG && response.VBG.countryOfSaleCode && response.VBG.countryOfSaleCode.length > 0){
        response.VBG.countryOfSaleCode.map((item:any) =>{
            if(item.alpha2){
                let nomePais = getCountryName(
                    item.alpha2
                )
                let country={
                    sigla:item.alpha2,
                    name:nomePais
                }
                countriesComplete.push(country)
            }

        })
    }
    if(statusFinal === 'INACTIVE')
    {
        
        vbg_gtin_record_status_message = `Este GTIN está inativo. Este GTIN não está mais sendo mantido atualizado pelo dono da marca. Caso precise de ajuda, entre em contato com a GS1 Brasil.`;
    }
    if(statusFinal === 'DISCONTINUED')
    {
        vbg_gtin_record_status_message = `Este GTIN está inativo. Este GTIN não está mais sendo mantido atualizado pelo dono da marca. Caso precise de ajuda, entre em contato com a GS1 Brasil.`;
    }
   
    let productData = 
    {   
        gtin: response.GTIN,
        vbg:{
            brandNameFull: (response.VBG && response.VBG.brandName && response.VBG.brandName.length > 0) ? response.VBG.brandName : unknown_item,
            brandName: (response.VBG && response.VBG.brandName && response.VBG.brandName.length > 0) ? response.VBG.brandName[0].value : unknown_text,
            brandNameLanguage: (response.VBG && response.VBG.brandName && response.VBG.brandName.length > 0) ? response.VBG.brandName[0].language : unknown_text,
            language: (response.VBG && response.VBG.brandName && response.VBG.brandName.length > 0) ? response.VBG.brandName[0].language : unknown_text,
            flag: flagvbg,
            country: countryvbg,
            productFull: (response.VBG && response.VBG.productDescription && response.VBG.productDescription.length>0) ? response.VBG.productDescription : unknown_item,
            productdescription: (response.VBG && response.VBG.productDescription && response.VBG.productDescription.length > 0) ? response.VBG.productDescription[0].value : unknown_text,
            productdescriptionLanguage: (response.VBG && response.VBG.productDescription && response.VBG.productDescription.length > 0) ? response.VBG.productDescription[0].language : unknown_text,
            productImage: vbgpic,
            productImageFull: (response.VBG && response.VBG.productImageUrl && response.VBG.productImageUrl.length>0) ? response.VBG.productImageUrl: unknown_item,
            productImageLanguage:  (response.VBG && response.VBG.productImageUrl && response.VBG.productImageUrl.length > 0) ? response.VBG.productImageUrl[0].language : unknown_text,
            countryFull: countriesComplete ,
            countrysalescode:{
            alpha: (response.VBG && response.VBG.countryOfSaleCode && response.VBG.countryOfSaleCode.length > 0) ? response.VBG.countryOfSaleCode[0].alpha2 : unknown_text,
            alpha1: (response.VBG && response.VBG.countryOfSaleCode && response.VBG.countryOfSaleCode.length > 0) ? response.VBG.countryOfSaleCode[0].alpha3 : unknown_text,
            numeric: (response.VBG && response.VBG.countryOfSaleCode && response.VBG.countryOfSaleCode.length > 0) ? response.VBG.countryOfSaleCode[0].numeric : unknown_text,
            },
            gpccategorycode: (response.VBG && response.VBG.gpcCategoryCode) ? response.VBG.gpcCategoryCode : unknown_text,
            gtin: (response.GTIN) ? response.GTIN : unknown_text,
            isComplete: (response.VBG && response.VBG.isComplete) ? response.VBG.isComplete : (response.VBG && !response.VBG.isComplete) ? null : '',
            licenseMessage: vbg_message_license,
            licenseMessageProduct: vbg_message_license_product,
            licenseName: response.VBG !=null? response.VBG.licenseeName ? response.VBG.licenseeName : response.VBG.gs1Licence? response.VBG.gs1Licence.licenseeName? response.VBG.gs1Licence.licenseeName:unknown_item:unknown_item:unknown_item,
            gs1Licence: (response.VBG && response.VBG.gs1Licence) ? response.VBG.gs1Licence : unknown_text,
            gtinRecordStatus: vbg_gtin_record_status_message,
            statusFinal: statusFinal,
            titleFinal : titleFinal,
            msgFinal : msgFinal,
            completoFinal : completoFinal,
            netContent:{
            unitCode: (response.VBG && response.VBG.netContent  && response.VBG.netContent.length > 0) ? getUnitName(response.VBG.netContent[0].unitCode) : '',
            value: (response.VBG && response.VBG.netContent && response.VBG.netContent.length > 0) ? response.VBG.netContent[0].value : unknown_text
            },
            hasError:{
                error: vbg_error_code,
                showpic: vbg_show_pic,
                title: vbg_title,
                message: vbg_error_message,
                message_type: statusFinal
            },
            containsProduct: (response.VBG!=null && response.VBG !==undefined) && (response.VBG.gs1Licence != undefined &&response.VBG.gs1Licence.licenseeName !=undefined),
            isNational: (response.mensagens.vbg!=null && response.mensagens.vbg !==undefined)?response.mensagens.vbg[0].isNational:"",
            linkset:linkset
        },
        cnp:{
            gtin: (response.CNP && response.CNP.product) ? response.CNP.product.gs1TradeItemIdentificationKey.fixedLengthGtin : unknown_text,
            gtinOrigin: (response.CNP && response.CNP.product) ? 
            (response.CNP && response.CNP.product.childTradeItems && response.CNP.product.childTradeItems.length > 0) ? response.CNP.product.childTradeItems[0].gtin : unknown_text: unknown_text,
            quantityGtinOrigin: (response.CNP && response.CNP.product) ? 
            (response.CNP && response.CNP.product.childTradeItems && response.CNP.product.childTradeItems.length > 0) ? response.CNP.product.childTradeItems[0].quantityOfNextLowerLevelTradeItem : unknown_text : unknown_text,
            brandNameLanguage: (response.CNP && response.CNP.product) ? (response.CNP && response.CNP.product.brandNameInformationLang && response.CNP.product.brandNameInformationLang.length > 0) ? 
            response.CNP.product.brandNameInformationLang[0].languageCode : unknown_text : unknown_text,
            brandName: (response.CNP && response.CNP.product) ? 
            (response.CNP && response.CNP.product.brandNameInformationLang && response.CNP.product.brandNameInformationLang.length > 0) ? 
            response.CNP.product.brandNameInformationLang[0].brandName : unknown_text : unknown_text,
            brandNameFull: (response.CNP && response.CNP.product) ? 
            (response.CNP && response.CNP.product.brandNameInformationLang && response.CNP.product.brandNameInformationLang.length > 0) ? 
            response.CNP.product.brandNameInformationLang : unknown_item : unknown_item,
            flag: flagcnp,
            country: countrycnp,
            productDescription: (response.CNP && response.CNP.product) ? response.CNP.product.tradeItemDescriptionInformationLang[0].tradeItemDescription : unknown_text,
            productFull: (response.CNP && response.CNP.product && response.CNP.product.tradeItemDescriptionInformationLang.length>0) ? response.CNP.product.tradeItemDescriptionInformationLang : unknown_item,
            productdescriptionLanguage: (response.CNP && response.CNP.product ) ? response.CNP.product.tradeItemDescriptionInformationLang[0].languageCode : unknown_text,
            productImage: cnppic,
            productImageLanguage: (response.CNP && response.CNP.product && response.CNP.product.referencedFileInformations.length > 0) ? response.CNP.product.referencedFileInformations[0].languageCode : unknown_text,
            productImageFull: (response.CNP && response.CNP.product && response.CNP.product.referencedFileInformations) ? 
            (response.CNP.product.referencedFileInformations.length>0)?
            response.CNP.product.referencedFileInformations: unknown_item:unknown_item,
            additionalProductImage: additionalCNPUrl,
            classification:(response.CNP && response.CNP.product) ? `${response.CNP.product.tradeItemClassification.gpcCategoryCode} (${response.CNP.product.tradeItemClassification.gpcCategoryName})` : unknown_text,
            netContent:{
                measurementUnitCode: (response.CNP && response.CNP.product && response.CNP.product.tradeItemMeasurements) ? (response.CNP.product.tradeItemMeasurements.netContent.measurementUnitCode) ? getUnitName(response.CNP.product.tradeItemMeasurements.netContent.measurementUnitCode) : '' : '',
                value: (response.CNP && response.CNP.product && response.CNP.product.tradeItemMeasurements) ? (response.CNP.product.tradeItemMeasurements.netContent.value) ? response.CNP.product.tradeItemMeasurements.netContent.value : unknown_text : unknown_text
            },
            grossWeight:{
                measurementUnitCode: (response.CNP && response.CNP.product && response.CNP.product.tradeItemWeight) ? (response.CNP.product.tradeItemWeight.grossWeight.measurementUnitCode) ? getUnitName(response.CNP.product.tradeItemWeight.grossWeight.measurementUnitCode) : '' : '',
                value: (response.CNP && response.CNP.product && response.CNP.product.tradeItemWeight) ? (response.CNP.product.tradeItemWeight.grossWeight.value) ? response.CNP.product.tradeItemWeight.grossWeight.value : unknown_text : unknown_text
            },
            ncm: (response.CNP && response.CNP.product) 
            ? (response.CNP && response.CNP.product.tradeItemClassification.additionalTradeItemClassifications && response.CNP.product.tradeItemClassification.additionalTradeItemClassifications.length > 0) 
            ? response.CNP.product.tradeItemClassification.additionalTradeItemClassifications[0].additionalTradeItemClassificationCodeValue
            : unknown_text
            : unknown_text,
            cest: (response.CNP && response.CNP.product) 
            ? (response.CNP &&  response.CNP.product.tradeItemClassification.additionalTradeItemClassifications.length > 0 && response.CNP.product.tradeItemClassification.additionalTradeItemClassifications[1]) 
            ? response.CNP.product.tradeItemClassification.additionalTradeItemClassifications[1].additionalTradeItemClassificationCodeValue
            : unknown_text
            : unknown_text,
            syncInformationCCG: (response.CNP && response.CNP.product) ? (response.CNP && response.CNP.product.syncInformationCCG) ? 'Sim' : 'Não' : unknown_text,
            hasError:{
                error: cnp_error_code,
                showpic: cnp_show_pic,
                title: cnp_title,
                message: cnp_error_message
            }
        },
        gepir:
        {
            text: gepir_message, 
            error: gepir_error_code,
        },
        validations:
        {
            descricaoAdicional: (response.validacoes && response.validacoes.descricaoAdicional) ? response.validacoes.descricaoAdicional : false,
            idiomaDescAdicional: (response.validacoes && response.validacoes.idiomaDescAdicional) ? response.validacoes.idiomaDescAdicional : false,
            idiomaImagemAdicional: (response.validacoes && response.validacoes.idiomaImagemAdicional) ? response.validacoes.idiomaImagemAdicional : false,
            idiomaMarcaAdicional: (response.validacoes && response.validacoes.idiomaMarcaAdicional) ? response.validacoes.idiomaMarcaAdicional : false,
            imagemAdicional: (response.validacoes && response.validacoes.imagemAdicional) ? response.validacoes.imagemAdicional : false,
            marcaAdicional: (response.validacoes && response.validacoes.marcaAdicional) ? response.validacoes.marcaAdicional : false,
            tipoGtin: (response.validacoes && response.validacoes.tipoGtin) ? response.validacoes.tipoGtin : false,
        },
        messages:
        {
            cnp:{
                error: (cnp_messages) ? cnp_messages.erro : false,
                message: (cnp_messages) ? cnp_messages.mensagem : '',
                title: (cnp_messages) ? cnp_messages.titulo : '',
                visible: (cnp_messages && !geral_messages) ? true : (geral_messages) ? false : true 
            },
            gepir:{
                error: (gepir_messages) ? gepir_messages.erro : false,
                message: (gepir_messages) ? gepir_messages.mensagem : '',
                title: (gepir_messages) ? gepir_messages.titulo : '',
                visible: (gepir_messages  && !geral_messages) ? gepir_messages.visivel : (geral_messages) ? false : true
            },
            vbg:{
                error: (vbg_messages && !geral_messages) ? vbg_messages.erro : (geral_messages) ? geral_messages.erro : false,
                message: (vbg_messages && !geral_messages) ? vbg_messages.mensagem : (geral_messages) ? geral_messages.mensagem : '',
                title: (vbg_messages && !geral_messages) ? vbg_messages.titulo : (geral_messages) ? geral_messages.titulo : '',
                visible: (vbg_messages) ? vbg_messages.visivel : true,
            },
            geral:{
                error: (geral_messages) ? (geral_messages.erro) ? geral_messages.erro : false : false,
                message: (geral_messages) ? geral_messages.mensagem : '',
                title: (geral_messages) ? geral_messages.titulo : ''
            }
        }
    }
    return productData;

}