

export function processValidationErrors(companiesData: any[], error: any) {
    for (var i = 0; i < companiesData.length; i++) {
        if (error.gln == companiesData[i].companyData.globalLocationNumber) {
            companiesData[i].errors = error;
            return companiesData
        }
    }
    companiesData.push(error)
    return companiesData;
}

export function processOtherKeyData(data: any, keyType: string) {


    let retornoTotal = {};
    let companyData = {};


    companyData = mountCompanyInformation(data, keyType);

    let contentMsg
  
    contentMsg = createErrorMsgStatus(data);
    
    
    let msgDate = createMsgDate(data);

    retornoTotal = {
        "companyData": companyData,
        "retornoMsg": contentMsg,
        "msgDate": msgDate
    }

    return retornoTotal;
}

function createMsgDate(data: any) {

    let _date = new Date(data.gs1Licence.dateUpdated);
    let _date2 = new Date(data.gs1Licence.dateCreated);
    let _date_format = _date.toLocaleDateString('pt-BR').split('/');
    let _date_format2 = _date2.toLocaleDateString('pt-BR').split('/');

    let months: Array<string> = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];

    let message_license = `Estas informações de licença foram fornecidas pela ${data.gs1Licence.licenseeName} em ${_date_format2[0]} de ${months[_date2.getMonth()]} de ${_date_format2[2]} e atualizadas pela última vez em ${_date_format[0]} de ${months[_date.getMonth()]} de ${_date_format[2]}.`;
    return message_license;

}
export function createErrorMsg(validationError: any) {
    let titleMsg = {}
   
        let vbg_error_code = 500;
        let vbg_show_pic = false;
        let vbg_title = `Erro ao buscar Chave`;
        let vbg_error_message = `O numero informado não pode ser encontrado na base de dados`
        titleMsg = {
            "vbg_error_code": vbg_error_code,
            "vbg_show_pic": vbg_show_pic,
            "show_content": false,
            "vbg_title": vbg_title,
            "vbg_error_message": vbg_error_message,
            "vbg_status": "error",
            "vbg_status_exportErrorvbg_status_exportError": true,
            "error": true,
            "showMSG": true
        }
    
    return titleMsg;
}

export function createErrorMsgStatus(data: any) {
        let titleMsg = {}
        let status = getStatus(data)
        let vbg_error_code = 500;
        let vbg_show_pic = false;
        let vbg_show_color = "success";
        let vbg_title = ``;
        let vbg_error_message = `Este número está registrado na empresa <b><strong> ${data.gs1Licence.licenseeName}</strong></b>.`
        
        if(status=="ACTIVE" || status=="OK"){
            vbg_error_code = 0;
            vbg_show_pic = true;
        }else{
        
            if(status=="INACTIVE"){
                vbg_show_color = "error"
                vbg_show_pic = false;
                
                vbg_error_message = `Este número está registrado na empresa <b><strong> ${data.gs1Licence.licenseeName}</strong></b>.  Este registro GLN está inativo. Este registro não está mais sendo atualizado.`

            }else{
                vbg_show_color = "warning"
                vbg_show_pic = true;
                vbg_error_message = `Este número está registrado na empresa <b><strong> ${data.gs1Licence.licenseeName}</strong></b>.  Este registro GLN está descontinuado. Este registro não está mais sendo atualizado.`

            }
        }
        
      
        titleMsg = {
            "vbg_error_code": vbg_error_code,
            "show_content": true,
            "vbg_show_pic": vbg_show_pic,
            "vbg_status": vbg_show_color,
            "vbg_status_exportError": false,
            "vbg_title": vbg_title,
            "vbg_error_message": vbg_error_message,
            "error": true,
            "showMSG": true
        }
    
    return titleMsg;
}

function getStatus(data: any){
    if(data.glnRecordStatus!=undefined){
        return data.glnRecordStatus
    }
    if(data.glnRecords!=undefined && data.glnRecords.length>0){
        if(data.glnRecords[0].glnRecordStatus!=undefined){
            return data.glnRecords[0].glnRecordStatus;
        }
    }
    return "ACTIVE";
}

function getValue(data: any, title: string) {
    if (data == undefined) {
        return "Não cadastrado";
    }
    if (!!data[title]) {
        return data[title]
    }

    return "Não cadastrado";
}


export function mountEnderecoArrayProduct(products:any[]){
    let enderecos: any[] =[]

    products.forEach(product=>{
        enderecos.push(product.endereco)
    })

    return enderecos;
}

export function mountEnderecoString(address: any) {

    let enderecoString =""

    if (!!address.postalName) {
        enderecoString += address.postalName.value + "\n";
    }
    if (!!address.streetAddress) {
        enderecoString += address.streetAddress.value + "\n";
    }
    if (!!address.streetAddressLine2) {
        enderecoString += address.streetAddressLine2.value + "\n";
    }
    if (!!address.addressLocality) {
        enderecoString += address.addressLocality.value + "\n";
    }

    if (!!address.addressSuburb) {
        enderecoString += address.addressSuburb.value + "\n";;
    }
   
    if (!!address.addressRegion) {
        enderecoString += address.addressRegion.value + "\n";
    }
    if (!!address.countryCode) {
        enderecoString += address.countryCode + "\n";
    }
    return enderecoString;
}

function mountEndereco(address: any) {
    let postalName = ""
    let countryCode = ""
    let addressRegion = ""
    let addressLocality = ""
    let streetAddress = ""
    let streetAddressLine2 = ""
    let addressSuburb = ""
    if (!!address.postalName) {
        postalName = address.postalName.value;
    }
    if (!!address.countryCode) {
        countryCode = address.countryCode;
    }
    if (!!address.addressRegion) {
        addressRegion = address.addressRegion.value;
    }
    if (!!address.addressLocality) {
        addressLocality = address.addressLocality.value;
    }
    if (!!address.streetAddress) {
        streetAddress = address.streetAddress.value;
    }
    if (!!address.streetAddressLine2) {
        streetAddressLine2 = address.streetAddressLine2.value;
    }
    if (!!address.addressSuburb) {
        addressSuburb = address.addressSuburb.value;
    }
    let enderecoFull = {
        "postalName": postalName,
        "countryCode": countryCode,
        "addressRegion": addressRegion,
        "addressLocality": addressLocality,
        "streetAddress": streetAddress,
        "streetAddressLine2": streetAddressLine2,
        "addressSuburb": addressSuburb
    }
    return enderecoFull;
}


function mountCompanyInformation(data: any, keyType: string) {
    if (!data.gs1Licence) {
        return {};
    }
    let companyAux = data.gs1Licence;

    // companyAux = getCompanyAuxData(data);
    let contactPoint = getValue(companyAux, "contactPoint")

    let companyData = {}
    
    companyData = {
        "nomeEmpresa": getValue(data.gs1Licence, "licenseeName"),
        "endereco": Array.isArray(getValue(data.gs1Licence, "address")) ? getValue(data.gs1Licence, "address")[0] : getValue(data.gs1Licence, "address"),
        "site": getValue(contactPoint, "website"),
        "tipoPrefixo": getValue(data.gs1Licence, "licenceType")=="GCP"?"GS1 Company Prefix":getValue(data.gs1Licence, "licenceType"),
        "status": data.glnRecordStatus,
        "globalLocationNumber": getValue(data.gs1Licence, "licenceKey"),
        "gln": getValue(data, "gln"),
        "orgMenbroGs1": getValue(data.gs1Licence.licensingMO, "moName"),
        "dateCreated": getValue(companyAux, "dateCreated"),
        "dateUpdated": getValue(companyAux, "dateUpdated"),
        "code": data[keyType.toLowerCase()],
        "mogln": data.gs1Licence.licensingMO.moGLN,        
        "keyType": keyType
        // "contatos": companyAux.contactPoint!=undefined? mountContatos(companyAux.contactPoint):"Não cadastrado"

    }
    return companyData;
}



