import { getConfig } from "../config/config";
import * as xlsx from "xlsx"
import * as FileSaver from "file-saver";
import { getCountryName } from './countryName';
import { traduzirTipoFuncao } from "./gln_process";


export function exportFileTestOtherKey(dataPrepared:any, filetype:string, numberMaxProduct:number){
  let headers =[]
  headers.push("Nome da Empresa");
  headers.push("Endereço");
  headers.push("Site");
  headers.push("Tipo de Prefixo");
  headers.push("GLN");
  headers.push("Organização Membro GS1");
  for (let index = 1; index <= numberMaxProduct; index++) {
    headers.push("gln_"+index+"_GLN");
    headers.push("gln_"+index+"_Tipo de GLN");
    headers.push("gln_"+index+"_Entidade Relacionada");
    headers.push("gln_"+index+"_Area de Atuacao");
    headers.push("gln_"+index+"_Numero de registro legal");
    headers.push("gln_"+index+"_enderecoDigital");
    headers.push("gln_"+index+"_endereco");
    headers.push("gln_"+index+"emails");
    headers.push("gln_"+index+"phones");
    headers.push("gln_"+index+"sites");
  }
 
  headers.push("Erro");

  let ws;
  if(numberMaxProduct>0)
    ws =xlsx.utils.json_to_sheet(dataPrepared,{header:headers});
  else
    ws =xlsx.utils.json_to_sheet(dataPrepared);

  
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  const excelBuffer = xlsx.write(wb, { bookType: filetype=="xls"?"xls":"csv", type: "array" });
  const data = new Blob([excelBuffer]);
  FileSaver.saveAs(data, "arquivo" + "."+filetype);
}


function concatenateEndereco(address:any){
  let retorno ="";

  if (!!address.postalName) {
      retorno += address.postalName.value;
    }
    if (!!address.countryCode) {
      retorno += address.countryCode;
    }
    if (!!address.addressRegion) {
      retorno += address.addressRegion.value;
    }
    if (!!address.addressLocality) {
      retorno += address.addressLocality.value;
    }
    if (!!address.streetAddress) {
      retorno += address.streetAddress.value;
    }
    if (!!address.streetAddressLine2) {
      retorno += address.streetAddressLine2.value;
    }
    if (!!address.addressSuburb) {
      retorno += address.addressSuburb.value;
    }

    return retorno
}





export function exportFileFrontEndOtherKeyError(data:any, keyType:any){

  let item={
    'gln': data[keyType.toLowerCase()], 
    'Erro': "Não foi possível encontrar esta chave" 
  }
   
  return item;
}

export function exportFileFrontEndOtherKey(data:any, keyType: string){

  let productData: any={}




  

  let item={
    'Nome da Empresa': data.companyData.nomeEmpresa, 
    'Endereço': concatenateEndereco(data.companyData.endereco), 
    'Site': data.companyData.site, 
    'Tipo de chave': data.companyData.keyType, 
    'Global Location Number (GLN)': data.companyData.globalLocationNumber, 
    'Organização Membro GS1': data.companyData.orgMenbroGs1 ,
    'Erro': data.retornoMsg.vbg_status!="success"?data.retornoMsg.vbg_status_exportError?data.retornoMsg.vbg_error_message:"":""    
  }
   
  return merge(productData,item);
}

function merge(obj1: any, obj2: any) {
  let merged = {
    ...obj1,
    ...obj2
  };
  Object.keys(obj1).filter(k => obj2.hasOwnProperty(k)).forEach((k) => {
    merged[k] = obj1[k] + "," + obj2[k]
  })
  return merged
}
